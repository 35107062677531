<script lang="ts">
import type { UserAccount } from 'src/graphql.types';
import { get } from 'svelte/store';
import { authStore, environment } from '../../stores';
import PdfViewer from 'svelte-pdf';

export let userAccount: UserAccount | undefined;
export let userCode: string;
export let businessId;

let pdfSrc: string | undefined;
let variant = 'dark';
let assetType = 'pdf';

function refetch() {
  assetType = 'pdf';
  pdfSrc = undefined;
  loadImage();
}

async function loadImage() {
  if (!pdfSrc) {
    try {
      const result = await downloadCall();
      pdfSrc = URL.createObjectURL(result);
    } catch (err) {
      console.log(err);
    }
  }
}

async function downloadCall() {
  const response = await fetch(
    `${environment.getBaseApi()}/api/v1/tip-cards-2?businessId=${businessId}&variant=${variant}&userId=${
      userAccount?.id
    }&type=${assetType}`,
    {
      headers: {
        Authorization: `Bearer ${get(authStore.token)}`,
      },
    },
  );
  return response.blob();
}

async function download() {
  if (assetType === 'pdf') {
    const fileLink = document.createElement('a');
    fileLink.href = pdfSrc;
    fileLink.download = `${userAccount?.firstName}_${userAccount?.lastName}-${businessId}`;
    fileLink.click();
  } else {
    const data = await downloadCall();
    const fileLink = document.createElement('a');
    fileLink.href = URL.createObjectURL(data);
    fileLink.download = `${userAccount?.firstName}_${userAccount?.lastName}-${businessId}`;
    fileLink.click();
  }
}
</script>

<!-- svelte-ignore a11y-missing-attribute -->
<a
  class="font-medium text-blue-600 hover:underline dark:text-blue-500"
  data-te-toggle="modal"
  data-te-target="#qrCodeModal-{userAccount?.id}"
  on:click="{loadImage}">Tip card</a
>

<div
  data-te-modal-init
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="qrCodeModal-{userAccount?.id}"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div
    data-te-modal-dialog-ref
    class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[700px]"
  >
    <div
      class="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none"
    >
      <div
        class="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4"
      >
        <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">
          Tip card for {userAccount?.firstName}
          {userAccount?.lastName} (<b>{userCode}</b>)
        </h5>
        <button
          type="button"
          class="btn-close box-content h-4 w-4 rounded-none border-none p-1 text-black opacity-50 hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body relative p-4">
        <div class="mb-2 flex justify-center">
          <select
            class="form-select m-0
              block
              w-full
              appearance-none
              rounded
              border
              border-solid
              border-gray-300
              bg-white bg-clip-padding bg-no-repeat
              px-3 py-1.5 text-base
              font-normal
              text-gray-700
              transition
              ease-in-out
              focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
            aria-label="Default select example"
            bind:value="{variant}"
            on:change="{refetch}"
          >
            <option value="dark">Dark</option>
            <option value="light">Light</option>
          </select>
        </div>
        {#if !pdfSrc}
          <div class="flex items-center justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                class="mr-2 h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                ></path>
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        {:else}
          <PdfViewer url="{pdfSrc}" scale="{0.4}" showBorder="{false}" />
          <br />
          <button
            type="button"
            class="w-full
          rounded
          bg-yellow-600
          px-6
          py-2.5
          text-xs
          font-medium
          uppercase
          leading-tight
          text-white
          shadow-md
          transition duration-150
          ease-in-out hover:bg-yellow-700 hover:shadow-lg focus:bg-yellow-700
          focus:shadow-lg focus:outline-none
          focus:ring-0
          active:bg-yellow-800
          active:shadow-lg"
            on:click="{download}">Download</button
          >
          <select
            class="form-select m-0
              block
              w-full
              appearance-none
              rounded
              border
              border-solid
              border-gray-300
              bg-white bg-clip-padding bg-no-repeat
              px-3 py-1.5 text-base
              font-normal
              text-gray-700
              transition
              ease-in-out
              focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
            aria-label="Default select example"
            bind:value="{assetType}"
          >
            <option value="pdf">PDF</option>
            <option value="image">Zip</option>
          </select>
        {/if}
      </div>
      <div
        class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-gray-200 p-4"
      >
        <button
          type="button"
          class="rounded
          bg-purple-600
          px-6
          py-2.5
          text-xs
          font-medium
          uppercase
          leading-tight
          text-white
          shadow-md
          transition duration-150
          ease-in-out hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700
          focus:shadow-lg focus:outline-none
          focus:ring-0
          active:bg-purple-800
          active:shadow-lg"
          data-te-modal-dismiss>Close</button
        >
      </div>
    </div>
  </div>
</div>
