<script lang="ts">
import { query } from 'svelte-apollo';
import { gql } from '@apollo/client/core';
import { useParams } from 'svelte-navigator';
import CreateOwnerModal from './CreateBusinessModal.svelte';
import Badge from '../../components/Badge.svelte';
import { formatDate } from '../../utils/formatDate';
import { environment } from '../../stores';
import { get } from 'svelte/store';
import { displayBalance } from '../../utils/displayBalance';

const params = useParams();

const account = query(
  gql`
    query getUserAccount($id: ID!) {
      userAccounts {
        forAdmin {
          userAccount(id: $id) {
            id
            type
            firstName
            lastName
            email
            phoneNumber
            createdAt
            ... on OwnerUserAccount {
              businesses {
                id
                name
                address
              }
            }
            ... on RegularUserAccount {
              avatar
              stripeData {
                accountCreated
                payoutsEnabled
                balance {
                  amount
                  currency {
                    code
                    decimalPlaces
                  }
                }
                pendingBalance {
                  amount
                  currency {
                    code
                    decimalPlaces
                  }
                }
                transitBalance {
                  amount
                  currency {
                    code
                    decimalPlaces
                  }
                }
                informationNeeded
                verificationInProgress
                accountId
              }
            }
          }
        }
      }
    }
  `,
  {
    variables: {
      id: $params.id,
    },
  },
);

$: graphData = $account.data as any;
$: userData = graphData?.userAccounts?.forAdmin?.userAccount;
</script>

<main class="mt-2 flex justify-center">
  <div class="w-full overflow-hidden bg-white shadow sm:rounded-lg md:w-4/6 lg:w-3/6">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        {userData?.firstName}
        {userData?.lastName} ({userData?.type})
      </h3>
    </div>
    <div class="border-t border-gray-200">
      <dl>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Id</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{userData?.id}</dd>
        </div>
        <div class="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Full name</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {userData?.firstName}
            {userData?.lastName}
          </dd>
        </div>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Account type</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{userData?.type}</dd>
        </div>
        <div class="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Email</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{userData?.email}</dd>
        </div>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Phone number</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {userData?.phoneNumber}
          </dd>
        </div>
        <div class="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Created at</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {formatDate(userData?.createdAt)}
          </dd>
        </div>

        {#if userData?.type === 'OWNER'}
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Businesses ({userData?.businesses?.length})
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <button
                class="rounded-md bg-green-600 px-4 py-1 text-sm text-white shadow-sm hover:bg-green-700"
                data-te-toggle="modal"
                data-te-target="#create-business-modal">+ Business</button
              >
              <CreateOwnerModal ownerId="{$params.id}" refresh="{account.refetch}" />
              {#each userData?.businesses as business}
                <div>
                  <a
                    class="font-medium text-blue-600 hover:underline dark:text-blue-500"
                    href="/businesses/{business.id}">{business.name} - {business.address}</a
                  >
                </div>
                <br />
              {/each}
            </dd>
          </div>
        {/if}

        {#if userData?.type === 'REGULAR'}
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Avatar</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {userData?.avatar ?? 'No avatar'}
            </dd>
          </div>
          <div class="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Stripe account ID</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <a
                class="font-medium text-blue-600 hover:underline dark:text-blue-500"
                target="_blank"
                href="{get(environment.environment) === 'prod'
                  ? 'https://dashboard.stripe.com/connect/accounts/'
                  : 'https://dashboard.stripe.com/test/connect/accounts/'}{userData?.stripeData
                  ?.accountId}/activity">{userData?.stripeData?.accountId}</a
              >
            </dd>
          </div>
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Stripe information</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              Account created? <Badge bg="gray"
                >{userData?.stripeData?.accountCreated ? 'Yes' : 'No'}</Badge
              >
              <br />
              Payouts enabled? <Badge bg="gray"
                >{userData?.stripeData?.payoutsEnabled ? 'Yes' : 'No'}</Badge
              >
              <br />
              <br />
              Verification in progress? <Badge bg="gray"
                >{userData?.stripeData?.verificationInProgress ? 'Yes' : 'No'}</Badge
              >
              <br />
              Information needed? <Badge bg="gray"
                >{userData?.stripeData?.informationNeeded ? 'Yes' : 'No'}</Badge
              >
              {#if userData?.stripeData?.balance}
                <br />
                <br />
                Available balance:
                <b>{displayBalance(userData?.stripeData?.balance)}</b>
                <br />
                Pending balance:
                <b>{displayBalance(userData?.stripeData?.pendingBalance)}</b>
                <br />
                Transit balance:
                <b>{displayBalance(userData?.stripeData?.transitBalance)}</b>
              {/if}
            </dd>
          </div>
        {/if}
      </dl>
    </div>
  </div>
</main>
