<script lang="ts">
import { gql } from '@apollo/client/core';
import { REQUIRED_FIELD } from '../../constants/messages';
import { mutation, query } from 'svelte-apollo';
import { useParams } from 'svelte-navigator';
import { createForm } from 'svelte-forms-lib';
import * as yup from 'yup';

const params = useParams();

const queryResult = query<any>(
  gql`
    query getBusiness($businessId: ID!) {
      businesses {
        forAdmin {
          businesses(
            filter: { businessIds: [$businessId] }
            pagination: { page: 1, recordsPerPage: 10 }
          ) {
            items {
              id
              type
              countryCode
              currencyCode
              name
              code
              address
            }
          }
        }
      }
    }
  `,
  {
    variables: {
      businessId: $params.id,
    },
  },
);

const updateBusinessMutation = mutation(gql`
  {
    business {
      name
    }
  }
`);

$: graphData = $queryResult.data as any;
$: business = graphData?.businesses?.forAdmin?.businesses?.items[0];

let loading = false;
let error;
const { form, errors, handleSubmit, validateField, updateInitialValues } = createForm({
  initialValues: {
    name: business?.name,
    address: business?.address,
  },
  validationSchema: yup.object().shape({
    name: yup.string().min(1).max(60).required(REQUIRED_FIELD),
    address: yup.string().min(1).max(60).required(REQUIRED_FIELD),
  }),
  onSubmit: async (params) => {
    loading = true;
    try {
      const result = await updateBusinessMutation({
        variables: {
          ...params,
          businessId: business?.id,
        },
      });

      if (result.errors) {
        error = result.errors[0].message;
      }
    } catch (err) {
      error = err.message;
      console.log(err);
    } finally {
      loading = false;
    }
  },
});

$: if (business) {
  updateInitialValues({
    name: business?.name,
    address: business?.address,
  });
}
</script>

<main class="mt-2 flex justify-center">
  <div class="w-full overflow-hidden bg-white shadow sm:rounded-lg">
    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg font-medium leading-6 text-gray-900">{business?.name}</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">{business?.address}</p>
      </div>
      <div class="w-full space-y-2 border-t border-gray-200 md:w-5/6">
        <input
          type="text"
          class="
              form-control
              m-0
              block
              w-full
              rounded
              border
              border-solid
              border-gray-300
              bg-white bg-clip-padding
              px-3 py-1.5 text-base
              font-normal
              text-gray-700
              transition
              ease-in-out
              focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none
            "
          bind:value="{$form.name}"
          on:blur="{() => !!$form.name?.length && validateField('name')}"
          placeholder="Business name"
        />
        <input
          type="text"
          class="
              form-control
              m-0
              block
              w-full
              rounded
              border
              border-solid
              border-gray-300
              bg-white bg-clip-padding
              px-3 py-1.5 text-base
              font-normal
              text-gray-700
              transition
              ease-in-out
              focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none
            "
          bind:value="{$form.address}"
          on:blur="{() => !!$form.address?.length && validateField('address')}"
          placeholder="Business address"
        />
        <button
          type="button"
          class="inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
          >Update business</button
        >
      </div>
    </div>
  </div>
</main>
