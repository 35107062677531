<script lang="ts">
import CreateOwnerAccountForm from './CreateUserForm.svelte';
import { query } from 'svelte-apollo';
import { gql } from '@apollo/client/core';
import { formatDate } from '../../utils/formatDate';
import ResetDemoAccount from './ResetDemoAccount.svelte';

let page = 1;
let id;
let phoneNumber;
let name;

const accounts = query(
  gql`
    query Accounts($page: Int!, $phoneNumber: String, $name: String, $id: ID) {
      userAccounts {
        forAdmin {
          userAccounts(
            filter: { phoneNumber: $phoneNumber, name: $name, id: $id }
            pagination: { page: $page, recordsPerPage: 10 }
          ) {
            items {
              id
              type
              email
              phoneNumber
              firstName
              lastName
              avatar
              createdAt
            }
            hasNextPage
          }
        }
      }
    }
  `,
  {
    variables: {
      page,
      phoneNumber,
      name,
      id,
    },
  },
);

$: businessesData = $accounts.data as any;

function handleNextPage() {
  page += 1;
  accounts.refetch({
    page,
  });
}

function handlePrevPage() {
  if (page > 1) {
    page -= 1;
    accounts.refetch({
      page,
    });
  }
}

function applyFilter() {
  accounts.refetch({
    page,
    phoneNumber: phoneNumber || undefined,
    name: name || undefined,
    id: id || undefined,
  });
}
</script>

<main>
  <div class="flex justify-center">
    <div class="w-full overflow-x-auto sm:rounded-lg xl:w-5/6">
      <div class="mt-3 flex flex-row space-x-2">
        <CreateOwnerAccountForm />
      </div>
      <div class="relative flex items-center py-5">
        <div class="flex-grow border-t border-gray-400"></div>
      </div>
      <div class="mb-5 flex flex-row items-center space-x-3">
        <input
          type="text"
          class="m-0 block w-48 rounded border border-solid border-gray-300
          bg-white bg-clip-padding
          px-3 py-1.5 text-base
          font-normal
          text-gray-700
          transition
          ease-in-out
          focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
          id="userId"
          placeholder="ID"
          bind:value="{id}"
          autocomplete="off"
        />
        <input
          type="text"
          class="m-0 block w-48 rounded border border-solid border-gray-300
          bg-white bg-clip-padding
          px-3 py-1.5 text-base
          font-normal
          text-gray-700
          transition
          ease-in-out
          focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
          id="firstName"
          placeholder="Name"
          bind:value="{name}"
          autocomplete="off"
        />
        <input
          type="text"
          class="m-0 block w-48 rounded border border-solid border-gray-300
          bg-white bg-clip-padding
          px-3 py-1.5 text-base
          font-normal
          text-gray-700
          transition
          ease-in-out
          focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
          id="phoneNumber"
          placeholder="Phone number"
          bind:value="{phoneNumber}"
          autocomplete="off"
        />
        <button
          class="w-32 rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          on:click="{applyFilter}">Apply filter</button
        >
      </div>
      <table class="w-full text-left text-sm text-gray-500 shadow-md dark:text-gray-400">
        <thead
          class="bg-gray-200 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-6 py-3">Id</th>
            <th scope="col" class="px-6 py-3">Name</th>
            <th scope="col" class="px-6 py-3">Type</th>
            <th scope="col" class="px-6 py-3">Email</th>
            <th scope="col" class="px-6 py-3">Phone</th>
            <th scope="col" class="px-6 py-3">Created at</th>
            <th scope="col" class="px-6 py-3">
              <span class="sr-only">View</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {#if $accounts.loading}
            Loading...
          {:else if $accounts.error}
            Error: {$accounts.error.message}
          {:else}
            {#each businessesData.userAccounts.forAdmin.userAccounts.items as user}
              <tr class="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
                <td class="px-6 py-4">{user.id}</td>
                <th
                  scope="row"
                  class="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  {user.firstName}
                  {user.lastName}
                </th>
                <td class="px-6 py-4">{user.type}</td>
                <td class="px-6 py-4">{user.email}</td>
                <td class="px-6 py-4">{user.phoneNumber}</td>
                <td class="px-6 py-4">{formatDate(user.createdAt)}</td>
                <td class="px-6 py-4 text-right">
                  <a
                    href="/users/{user.id}"
                    class="font-medium text-blue-600 hover:underline dark:text-blue-500">View</a
                  >
                </td>
              </tr>
            {/each}
          {/if}
        </tbody>
      </table>

      <div class="spacing-x-3 mt-2 w-full">
        <button
          disabled="{page <= 1 || $accounts.loading}"
          on:click="{handlePrevPage}"
          class="w-32 rounded-md bg-gray-200 px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-300 disabled:bg-neutral-200 disabled:text-gray-400 disabled:opacity-40"
          >Previous page</button
        >
        <button
          disabled="{!businessesData?.userAccounts?.forAdmin?.userAccounts?.hasNextPage ||
            $accounts.loading}"
          on:click="{handleNextPage}"
          class="w-32 rounded-md bg-gray-200 px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-300 disabled:bg-neutral-200 disabled:text-gray-400 disabled:opacity-40"
          >Next page</button
        >
      </div>
    </div>
  </div>
</main>
