import { derived, writable, get } from 'svelte/store';
import { gql } from '@apollo/client/core';
import { appolo } from './';
import type { UserAccount } from 'src/graphql.types';

const user = writable(null as UserAccount);
const token = writable(localStorage.getItem('token'));
const getMeStatus = writable('idle' as FetchStatus);
const authenticateStatus = writable('idle' as FetchStatus);

const isAuthenticated = derived(token, ($token) => !!$token, false);

const authenticate = async (payload: {
  email: string;
  password: string;
}): Promise<StoreActionResponse> => {
  try {
    authenticateStatus.update(() => 'fetching');

    const { email, password } = payload;
    const response = await get(appolo.client).mutate({
      mutation: gql`
        mutation ($phoneNumber: String!, $password: String!) {
          userAccounts {
            authenticate(
              input: { phoneNumber: $phoneNumber, password: $password, accountType: ADMIN }
            ) {
              token {
                id
                expiresAt
              }
              userAccount {
                id
                firstName
                lastName
                email
              }
            }
          }
        }
      `,
      variables: {
        phoneNumber: email,
        password,
      },
    });

    const responseData = response?.data?.userAccounts?.authenticate;

    if (response.errors) {
      authenticateStatus.update(() => 'error');

      return {
        status: 400,
      };
    }

    localStorage.setItem('token', responseData?.token?.id);
    token.update(() => responseData?.token?.id);
    user.update((prevState) => ({ ...prevState, ...responseData?.userAccount }));
    authenticateStatus.update(() => 'success');

    return { status: 200 };
  } catch (error) {
    console.warn(error);

    authenticateStatus.update(() => 'error');

    return {
      status: 400,
    };
  }
};

const getMe = async (): Promise<StoreActionResponse> => {
  try {
    getMeStatus.update(() => 'fetching');

    const response = await get(appolo.client).query({
      query: gql`
        {
          me {
            userAccount {
              id
              firstName
              lastName
            }
          }
        }
      `,
    });

    user.update((prevState) => ({ ...prevState, ...response.data?.me?.userAccount }));
    getMeStatus.update(() => 'success');

    return { status: 200 };
  } catch (error) {
    console.warn(error);

    getMeStatus.update(() => 'error');

    return {
      status: 400,
    };
  }
};

const unauthenticate = () => {
  localStorage.removeItem('token');
  user.update(() => null);
  token.set(null);
};

if (localStorage.getItem('token')) {
  setTimeout(() => {
    void getMe();
  }, 500);
}

export default {
  user,
  token,
  authenticateStatus,
  getMeStatus,
  isAuthenticated,
  authenticate,
  getMe,
  unauthenticate,
};
