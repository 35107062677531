import { get, writable } from 'svelte/store';

const environment = writable(localStorage.getItem('environment') ?? 'prod');

function changeEnvironment(env: string) {
  environment.set(env);
  localStorage.setItem('environment', env);
}

function getBaseApi() {
  switch (get(environment)) {
    case 'dev':
      return 'https://api-dev.coinless.tips';
    case 'prod':
      return 'https://api.coinless.tips';
    default:
      return 'http://localhost:3000';
  }
}

export default {
  environment,
  changeEnvironment,
  getBaseApi,
};
