<script lang="ts">
import { get } from 'svelte/store';
import { authStore, environment } from '../../stores';
import * as axios from 'axios';

export let businessId: string;
export let refresh;

let filesVar;

async function uploadLogo() {
  if (filesVar) {
    let fd = new FormData();
    fd.append('image', filesVar[0]);
    await axios.default.put(
      `${environment.getBaseApi()}/api/v1/business-logos/${businessId}`,
      fd,
      {
        headers: {
          Authorization: `Bearer ${get(authStore.token)}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    document.getElementById('close-button-upload-modal').click();
    refresh();
  }
}
</script>

<!-- svelte-ignore a11y-missing-attribute -->
<a
  class="font-medium text-blue-600 hover:underline dark:text-blue-500"
  data-te-toggle="modal"
  data-te-target="#upload-logo">Upload logo</a
>

<div
  data-te-modal-init
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="upload-logo"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div
    data-te-modal-dialog-ref
    class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px]"
  >
    <div
      class="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none"
    >
      <div
        class="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4"
      >
        <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">
          Upload logo
        </h5>
        <button
          type="button"
          class="btn-close box-content h-4 w-4 rounded-none border-none p-1 text-black opacity-50 hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body relative p-4">
        <div class="mb-2 flex justify-center">
          <input
            accept="image/png, image/jpeg, image/jpg"
            type="file"
            bind:files="{filesVar}"
          />
        </div>
      </div>
      <div
        class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-gray-200 p-4"
      >
        <button
          id="close-button-upload-modal"
          type="button"
          class="rounded
          bg-purple-600
          px-6
          py-2.5
          text-xs
          font-medium
          uppercase
          leading-tight
          text-white
          shadow-md
          transition duration-150
          ease-in-out hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700
          focus:shadow-lg focus:outline-none
          focus:ring-0
          active:bg-purple-800
          active:shadow-lg"
          data-te-modal-dismiss>Close</button
        >

        <button
          on:click="{uploadLogo}"
          class="ml-1
              rounded
              bg-green-600
              px-6
              py-2.5
              text-xs
              font-medium
              uppercase
              leading-tight
              text-white
              shadow-md transition
              duration-150 ease-in-out hover:bg-green-700 hover:shadow-lg
              focus:bg-green-700 focus:shadow-lg
              focus:outline-none
              focus:ring-0
              active:bg-green-800
              active:shadow-lg"
          disabled="{!filesVar}"
        >
          Upload logo
        </button>
      </div>
    </div>
  </div>
</div>
