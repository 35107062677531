<script lang="ts">
import type { BusinessTeam } from 'src/graphql.types';
import { get } from 'svelte/store';
import { authStore, environment } from '../../stores';
import PdfViewer from 'svelte-pdf';
import Loader from '../../components/Loader.svelte';

export let team: BusinessTeam;
export let businessId;

let imageSrc: string | undefined;
let variant = 'dark';
let codeType = 'full';

function refetch() {
  imageSrc = undefined;
  loadImage();
}

function loadImage() {
  if (!imageSrc) {
    fetch(
      `${environment.getBaseApi()}/api/v1/tip-cards?businessId=${businessId}&variant=${variant}&teamId=${
        team?.id
      }&codeType=${codeType}`,
      {
        headers: {
          Authorization: `Bearer ${get(authStore.token)}`,
        },
      },
    )
      .then((response) => {
        response
          .blob()
          .then((blob) => {
            imageSrc = URL.createObjectURL(blob);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }
}

function download() {
  const fileLink = document.createElement('a');
  fileLink.href = imageSrc;
  fileLink.download = `${team?.name}-${businessId}`;
  fileLink.click();
}
</script>

<!-- svelte-ignore a11y-missing-attribute -->
<a
  class="font-medium text-blue-600 hover:underline dark:text-blue-500"
  data-te-toggle="modal"
  data-te-target="#qrCodeModal-{team?.id}"
  on:click="{loadImage}">Tip card</a
>

<div
  data-te-modal-init
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="qrCodeModal-{team?.id}"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div
    data-te-modal-dialog-ref
    class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[700px]"
  >
    <div
      class="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none"
    >
      <div
        class="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4"
      >
        <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">
          Tip card for {team?.name} (<b>{team?.code}</b>)
        </h5>
        <button
          type="button"
          class="btn-close box-content h-4 w-4 rounded-none border-none p-1 text-black opacity-50 hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body relative p-4">
        <div class="mb-2 flex justify-center">
          <select
            class="form-select m-0
              block
              w-full
              appearance-none
              rounded
              border
              border-solid
              border-gray-300
              bg-white bg-clip-padding bg-no-repeat
              px-3 py-1.5 text-base
              font-normal
              text-gray-700
              transition
              ease-in-out
              focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
            aria-label="Default select example"
            bind:value="{variant}"
            on:change="{refetch}"
          >
            <option value="dark">Dark</option>
            <option value="light">Light</option>
          </select>
        </div>
        <div class="mb-2 flex justify-center">
          <select
            class="form-select m-0
              block
              w-full
              appearance-none
              rounded
              border
              border-solid
              border-gray-300
              bg-white bg-clip-padding bg-no-repeat
              px-3 py-1.5 text-base
              font-normal
              text-gray-700
              transition
              ease-in-out
              focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
            aria-label="Default select example"
            bind:value="{codeType}"
            on:change="{refetch}"
          >
            <option value="full">Regular</option>
            <option value="direct">Direct</option>
          </select>
        </div>
        {#if !imageSrc}
          <div class="flex items-center justify-center">
            <Loader size="{50}" />
          </div>
        {:else}
          <PdfViewer url="{imageSrc}" scale="{0.4}" showBorder="{false}" />
          <br />
          <button
            type="button"
            class="w-full
          rounded
          bg-yellow-600
          px-6
          py-2.5
          text-xs
          font-medium
          uppercase
          leading-tight
          text-white
          shadow-md
          transition duration-150
          ease-in-out hover:bg-yellow-700 hover:shadow-lg focus:bg-yellow-700
          focus:shadow-lg focus:outline-none
          focus:ring-0
          active:bg-yellow-800
          active:shadow-lg"
            on:click="{download}">Download</button
          >
        {/if}
      </div>
      <div
        class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-gray-200 p-4"
      >
        <button
          type="button"
          class="rounded
          bg-purple-600
          px-6
          py-2.5
          text-xs
          font-medium
          uppercase
          leading-tight
          text-white
          shadow-md
          transition duration-150
          ease-in-out hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700
          focus:shadow-lg focus:outline-none
          focus:ring-0
          active:bg-purple-800
          active:shadow-lg"
          data-te-modal-dismiss>Close</button
        >
      </div>
    </div>
  </div>
</div>
