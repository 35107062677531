<script lang="ts">
import { query } from 'svelte-apollo';
import { gql } from '@apollo/client/core';
import { formatDate } from '../utils/formatDate';

const businesses = query(gql`
  {
    businesses {
      forAdmin {
        businesses(pagination: { page: 1, recordsPerPage: 15 }) {
          items {
            id
            name
            type
            address
            ... on BusinessForAdmin {
              ownerAccount {
                id
                firstName
                lastName
              }
              createdAt
            }
          }
        }
      }
    }
  }
`);

$: businessesData = $businesses.data as any;
</script>

<main>
  <div class="flex justify-center">
    <div class="mt-5 w-full overflow-x-auto sm:rounded-lg md:w-5/6">
      <table class="w-full text-left text-sm text-gray-500 shadow-md dark:text-gray-400">
        <thead
          class="bg-gray-200 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-6 py-3">Id</th>
            <th scope="col" class="px-6 py-3">Name</th>
            <th scope="col" class="px-6 py-3">Address</th>
            <th scope="col" class="px-6 py-3">Owner</th>
            <th scope="col" class="px-6 py-3">Type</th>
            <th scope="col" class="px-6 py-3">Created at</th>
            <th scope="col" class="px-6 py-3">
              <span class="sr-only">View</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {#if $businesses.loading}
            Loading...
          {:else if $businesses.error}
            Error: {$businesses.error.message}
          {:else}
            {#each businessesData.businesses.forAdmin.businesses.items as business}
              <tr class="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
                <td class="px-6 py-4">{business.id}</td>
                <th
                  scope="row"
                  class="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  {business.name}
                </th>
                <td class="px-6 py-4">{business.address}</td>
                <td class="px-6 py-4"
                  ><a
                    class="font-medium text-blue-600 hover:underline dark:text-blue-500"
                    href="/users/{business.ownerAccount.id}"
                    >{business.ownerAccount?.firstName} {business.ownerAccount?.lastName}</a
                  ></td
                >
                <td class="px-6 py-4">{business.type}</td>
                <td class="px-6 py-4">{formatDate(business.createdAt)}</td>
                <td class="px-6 py-4 text-right">
                  <a
                    href="/businesses/{business.id}"
                    class="font-medium text-blue-600 hover:underline dark:text-blue-500">View</a
                  >
                </td>
              </tr>
            {/each}
          {/if}
        </tbody>
      </table>
    </div>
  </div>
</main>
