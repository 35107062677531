<script lang="ts">
import { createForm } from 'svelte-forms-lib';
import * as yup from 'yup';
import { REQUIRED_FIELD } from '../../constants/messages';
import Loader from '../../components/Loader.svelte';
import { mutation } from 'svelte-apollo';
import { gql } from '@apollo/client/core';

export let team;
export let business;
export let refresh;

$: teamMemberIds = team.memberIds ?? [];
$: membersWithoutTeam = business?.members?.filter(
  (m) => !business?.teams?.find((t) => t.memberIds.includes(m.userAccount?.id)),
);
let loading = false;

const updateTeamMutation = mutation(gql`
  mutation updateTeam($businessId: ID!, $teamId: ID!, $name: String!, $leadUserId: ID) {
    businesses {
      forAdmin {
        updateTeam(
          input: {
            businessId: $businessId
            teamId: $teamId
            name: $name
            leadUserId: $leadUserId
          }
        ) {
          completed
        }
      }
    }
  }
`);

const updateMemberMutation = mutation(gql`
  mutation updateMember($businessId: ID!, $memberId: ID!, $teamId: ID!) {
    businesses {
      forAdmin {
        updateMember(input: { businessId: $businessId, memberId: $memberId, teamId: $teamId }) {
          result
        }
      }
    }
  }
`);
const removeMember = mutation(gql`
  mutation updateMember($businessId: ID!, $memberId: ID!) {
    businesses {
      forAdmin {
        updateMember(input: { businessId: $businessId, memberId: $memberId, teamId: "none" }) {
          result
        }
      }
    }
  }
`);

const removeMemberHandler = (id: string) => () => {
  removeMember({
    variables: {
      businessId: business?.id,
      memberId: id,
    },
  }).finally(() => {
    refresh();
  });
};

let error;

const { form, errors, handleSubmit, validateField } = createForm({
  initialValues: {
    name: team?.name ?? '',
    leaderId: team?.leadUserId,
  },
  validationSchema: yup.object().shape({
    name: yup.string().min(1).max(30).required(REQUIRED_FIELD),
    leaderId: yup.string(),
  }),
  onSubmit: async (params) => {
    loading = true;
    try {
      const result = await updateTeamMutation({
        variables: {
          businessId: business?.id,
          teamId: team?.id,
          name: params.name,
          leadUserId: params.leaderId || undefined,
        },
      });

      if (result.errors) {
        error = result.errors[0].message;
      } else {
        refresh();
      }
    } catch (err) {
      error = err.message;
      console.log(err);
    } finally {
      loading = false;
    }
  },
});

const {
  form: form2,
  handleSubmit: handleSubmit2,
  validateField: validateField2,
} = createForm({
  initialValues: {
    userId: '',
  },
  validationSchema: yup.object().shape({
    userId: yup.string().required(),
  }),
  onSubmit: async (params) => {
    try {
      const result = await updateMemberMutation({
        variables: {
          businessId: business?.id,
          teamId: team?.id,
          memberId: params.userId,
        },
      });

      if (result.errors) {
        error = result.errors[0].message;
      } else {
        refresh();
      }
    } catch (err) {
      error = err.message;
      console.log(err);
    }
  },
});

const inputClass = `"
          form-control
          block
          w-full
          px-3
          py-1.5
          text-base
          font-normal
          text-gray-700
          bg-white bg-clip-padding
          border border-solid border-gray-300
          rounded
          transition
          ease-in-out
          m-0
          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
          invalid:border-pink-500 invalid:text-pink-600
        "`;
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-missing-attribute -->
<a
  class="font-medium text-blue-600 hover:underline dark:text-blue-500"
  data-te-toggle="modal"
  data-te-target="#team-settings-{team?.id}">Team settings</a
>

<div
  data-te-modal-init
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="team-settings-{team?.id}"
  tabindex="-1"
  aria-labelledby="team-settings-label"
  aria-hidden="true"
>
  <div
    data-te-modal-dialog-ref
    class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px]"
  >
    <div
      class="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none"
    >
      <div
        class="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4"
      >
        <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">
          Team - <b>{team?.name}</b>
        </h5>
        <button
          data-te-modal-dismiss
          type="button"
          class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        >
          <span class="sr-only">Close menu</span>
          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <p class="pl-5 font-semibold text-gray-400">ID: {team?.id}</p>
      {#if error}
        <div class="mt-2 flex justify-center">
          <div
            class="mb-3 mb-4 w-5/6 rounded-lg bg-red-100 px-6 py-5 text-base text-red-700"
            role="alert"
          >
            {error}
          </div>
        </div>
      {/if}
      <div class="modal-body relative space-y-2 p-4">
        <form class:error="{!!$errors.name}" on:submit="{handleSubmit}">
          <p class="font-semibold text-gray-900">Team name</p>
          <input
            type="text"
            class="{inputClass}"
            id="teamName"
            placeholder="Team name"
            bind:value="{$form.name}"
            on:blur="{() => !!$form.name?.length && validateField('name')}"
            autocomplete="off"
          />
          <span class="text-red-700">{$errors.name}</span>
          <p class="font-semibold text-gray-900">Team leader</p>
          <select
            class="form-select m-0
            block
            w-full
            appearance-none
            rounded
            border
            border-solid
            border-gray-300
            bg-white bg-clip-padding bg-no-repeat
            px-3 py-1.5 text-base
            font-normal
            text-gray-700
            transition
            ease-in-out
            focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
            aria-label="Environemnt select"
            bind:value="{$form.leaderId}"
            on:blur="{() => !!$form.leaderId && validateField('leaderId')}"
            placeholder="User"
          >
            <option value="{''}"><em>None</em></option>
            {#each business?.members ?? [] as member}
              <option value="{member.userAccount?.id}"
                >{member.userAccount?.firstName} {member.userAccount?.lastName}</option
              >
            {/each}
          </select>
          <span class="text-red-700">{$errors.leaderId}</span>
          <br />
          <button
            type="submit"
            class="mb-2 mr-2 rounded-lg bg-green-700 px-4 py-2 text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            {#if loading}
              <Loader color="#fff" />
            {:else}
              Update team
            {/if}
          </button>
        </form>
        <br />
        <br />
        <p class="font-semibold text-gray-900">Team members</p>
        <ul class="max-w-md list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
          {#each teamMemberIds as memberId}
            <li>
              {business?.members?.find((m) => m.userAccount?.id === memberId)?.userAccount
                ?.firstName}
              {business?.members?.find((m) => m.userAccount?.id === memberId)?.userAccount
                ?.lastName}
              <button
                on:click="{removeMemberHandler(memberId)}"
                type="button"
                class="mb-2 mr-2 rounded-lg bg-red-700 px-2 py-0.5 text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                >Remove</button
              >
            </li>
          {/each}
          {#if teamMemberIds?.length === 0}
            None
          {/if}
        </ul>
        <form on:submit="{handleSubmit2}">
          <p class="font-semibold text-gray-900">Add member</p>
          <select
            class="form-select m-0
            block
            w-full
            appearance-none
            rounded
            border
            border-solid
            border-gray-300
            bg-white bg-clip-padding bg-no-repeat
            px-3 py-1.5 text-base
            font-normal
            text-gray-700
            transition
            ease-in-out
            focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
            aria-label="Environemnt select"
            bind:value="{$form2.userId}"
            on:blur="{() => !!$form2.userId && validateField2('userId')}"
            placeholder="User"
          >
            <option value="{''}"><em>None</em></option>
            {#each membersWithoutTeam ?? [] as member}
              <option value="{member.userAccount?.id}"
                >{member.userAccount?.firstName} {member.userAccount?.lastName}</option
              >
            {/each}
          </select>
          <button
            type="submit"
            class="mb-2 mr-2 rounded-lg bg-green-700 px-2 py-0.5 text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
            >Add</button
          >
        </form>
      </div>
      <div
        class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-gray-200 p-4"
      >
        <button
          id="close-button-create-team"
          type="button"
          class="rounded
                bg-gray-500
                px-6
                py-2.5
                text-xs
                font-medium
                uppercase
                leading-tight
                text-white
                shadow-md
                transition duration-150
                ease-in-out hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700
                focus:shadow-lg focus:outline-none
                focus:ring-0
                active:bg-gray-800
                active:shadow-lg"
          data-te-modal-dismiss>Close</button
        >
      </div>
    </div>
  </div>
</div>
