<script>
import { authStore, environment } from '../stores';
import { navigate, useLocation } from 'svelte-navigator';
import { get } from 'svelte/store';

const { unauthenticate, user } = authStore;
let showDropdown = false;
let location = useLocation();
let pathName = $location.pathname;

const selectedClasses = 'bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium';
const nonSelectedClasses =
  'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium';

function toggleDropDown() {
  showDropdown = !showDropdown;
}
</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<nav class="bg-gray-800">
  <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
    <div class="relative flex h-16 items-center justify-between">
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <!-- Mobile menu button-->
        <button
          type="button"
          class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="block h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
          <svg
            class="hidden h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
        <div class="flex flex-shrink-0 items-center" on:click="{() => navigate('/')}">
          <img class="block h-8 w-auto" src="/logoWhite.png" width="200px" alt="Workflow" /><sup
            class="text-gray-50">{get(environment.environment)}</sup
          >
        </div>
        <div class="hidden sm:ml-6 sm:block">
          <div class="flex space-x-4">
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <button
              on:click="{() => navigate('/')}"
              class="{pathName === '/' || pathName.startsWith('/businesses')
                ? selectedClasses
                : nonSelectedClasses}"
              aria-current="page">Businesses</button
            >
            <button
              on:click="{() => navigate('/users')}"
              class="{pathName.startsWith('/users') ? selectedClasses : nonSelectedClasses}"
              >Users</button
            >
            <button
              on:click="{() => navigate('/transactions')}"
              class="{pathName.startsWith('/transactions')
                ? selectedClasses
                : nonSelectedClasses}">Transactions</button
            >
          </div>
        </div>
      </div>
      <div
        class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
      >
        <!-- Profile dropdown -->
        <div class="relative ml-3">
          <div>
            <button
              on:click="{toggleDropDown}"
              type="button"
              class="text-gray-100"
              id="user-menu-button"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <div class="flex items-center justify-start justify-center space-x-2">
                <span class="hidden md:block">{$user?.firstName} {$user?.lastName}</span>
                <span class="display md:hidden">{$user?.firstName}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </button>
          </div>

          {#if showDropdown}
            <div
              class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabindex="-1"
            >
              <a
                on:click="{unauthenticate}"
                class="block px-4 py-2 text-sm text-red-700"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-2">Sign out</a
              >
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div class="sm:hidden" id="mobile-menu">
    <div class="space-y-1 px-2 pb-3 pt-2">
      <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
      <button
        on:click="{() => navigate('/')}"
        class="block w-full rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white"
        aria-current="page">Businesses</button
      >

      <button
        on:click="{() => navigate('/users')}"
        class="block w-full rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
        >Users</button
      >
    </div>
  </div>
</nav>
