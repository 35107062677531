<script lang="ts">
import { query } from 'svelte-apollo';
import { formatDate } from '../../utils/formatDate';
import { displayBalance } from '../../utils/displayBalance';
import { Datepicker, Input, initTE } from 'tw-elements';
import { onMount } from 'svelte';
import { createForm } from 'svelte-forms-lib';
import * as yup from 'yup';
import { REQUIRED_FIELD } from '../../constants/messages';
import { parse } from 'date-fns';
import { queryStore, gql, getContextClient } from '@urql/svelte';

let startDate = undefined;
let endDate = undefined;

$: summaryQuery = queryStore({
  client: getContextClient(),
  query: gql`
    query summary($startDate: String!, $endDate: String!) {
      transactions {
        forAdmin {
          transactionsSummary(filter: { startDate: $startDate, endDate: $endDate }) {
            summaries {
              currencyCode
              numberOfTransactions
              totalSum
              totalPlatformFee
              totalProviderFee
            }
          }
        }
      }
    }
  `,
  variables: { startDate, endDate },
});

onMount(() => {
  initTE({ Datepicker, Input });
});

let page = 1;

const transactionsQuery = query(
  gql`
    query getTransactions($type: TransactionType, $page: Int!) {
      transactions {
        forAdmin {
          transactions(
            filter: { type: $type }
            pagination: { page: $page, recordsPerPage: 10 }
          ) {
            items {
              id
              externalId
              type
              state
              totalAmount {
                amount
                currency {
                  code
                  decimalPlaces
                }
              }
              business {
                id
                name
              }
              taxAmount {
                amount
                currency {
                  code
                  decimalPlaces
                }
              }
              providerFee {
                amount
                currency {
                  code
                  decimalPlaces
                }
              }
              createdAt
            }
            hasNextPage
          }
        }
      }
    }
  `,
  {
    variables: {
      type: undefined,
      page: page,
    },
  },
);

function handleNextPage() {
  page += 1;
  transactionsQuery.refetch({
    page,
  });
}

function handlePrevPage() {
  if (page > 1) {
    page -= 1;
    transactionsQuery.refetch({
      page,
    });
  }
}

$: graphData = $transactionsQuery?.data as any;
$: transactions = graphData?.transactions?.forAdmin?.transactions;

const { form, errors, handleSubmit, validateField } = createForm({
  initialValues: {
    startDate: '',
    endDate: '',
  },
  validationSchema: yup.object().shape({
    startDate: yup.string().required(REQUIRED_FIELD),
    endDate: yup.string().required(REQUIRED_FIELD),
  }),
  onSubmit: (params) => {
    summaryQuery.resume();
    startDate = parse(params.startDate, 'dd/MM/yyyy', new Date()).toISOString();
    endDate = parse(params.endDate, 'dd/MM/yyyy', new Date()).toISOString();
  },
});
</script>

<main>
  <div class="flex justify-center">
    <div class="mt-5 w-full overflow-x-auto sm:rounded-lg md:w-5/6">
      <div
        class="w-full bg-gray-200 p-2 uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400"
      >
        Tip statistics
      </div>
      <div class="bg-white p-5">
        <div class="flex justify-start gap-x-2">
          <form class:error="{!!$errors.endDate}" on:submit="{handleSubmit}">
            <div class="flex justify-start gap-x-2 bg-white">
              <div
                class="relative xl:w-96"
                id="date-picker-start"
                data-te-datepicker-init
                data-te-inline="true"
                data-te-input-wrapper-init
              >
                <input
                  type="text"
                  class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                  placeholder="Select a date"
                  id="startDate"
                  bind:value="{$form.startDate}"
                  autocomplete="off"
                  on:blur="{() => !!$form.startDate?.length && validateField('startDate')}"
                />
                <label
                  for="floatingInput"
                  class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                  >Select a start date</label
                >
                <span class="text-red-700">{$errors.startDate}</span>
              </div>
              <div
                class="relative xl:w-96"
                data-te-datepicker-init
                data-te-inline="true"
                data-te-input-wrapper-init
              >
                <input
                  type="text"
                  class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                  placeholder="Select a date"
                  id="endDate"
                  bind:value="{$form.endDate}"
                  autocomplete="off"
                  on:blur="{() => !!$form.endDate?.length && validateField('endDate')}"
                />
                <span class="text-red-700">{$errors.endDate}</span>
                <label
                  for="floatingInput"
                  class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                  >Select an end date</label
                >
              </div>
              <button
                class="w-32 rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                type="submit">Calculate</button
              >
            </div>
          </form>
        </div>

        {#if $summaryQuery.fetching}
          Loading...
        {:else if $summaryQuery.error}
          {#if startDate && endDate}
            <p>Error: {$summaryQuery.error.message}</p>
          {/if}
        {:else if $summaryQuery?.data?.transactions?.forAdmin?.transactionsSummary}
          <br />
          <table class="w-full text-left text-sm text-gray-500 shadow-md dark:text-gray-400">
            <thead
              class="bg-gray-200 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="px-6 py-3">Currency</th>
                <th scope="col" class="px-6 py-3">Number of transactions</th>
                <th scope="col" class="px-6 py-3">Total tips</th>
                <th scope="col" class="px-6 py-3">Total platform fee</th>
                <th scope="col" class="px-6 py-3">Total provider fee</th>
                <th scope="col" class="px-6 py-3">Fee difference</th>
              </tr>
            </thead>
            <tbody>
              {#each $summaryQuery?.data?.transactions?.forAdmin?.transactionsSummary?.summaries ?? [] as summary}
                <tr class="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
                  <td class="px-6 py-4">{summary.currencyCode}</td>
                  <td class="px-6 py-4">{summary.numberOfTransactions}</td>
                  <td class="px-6 py-4"
                    >{displayBalance({
                      amount: summary.totalSum,
                      currency: { decimalPlaces: 2, code: summary.currencyCode },
                    })}</td
                  >
                  <td class="px-6 py-4"
                    >{displayBalance({
                      amount: summary.totalPlatformFee,
                      currency: { decimalPlaces: 2, code: summary.currencyCode },
                    })}</td
                  >
                  <td class="px-6 py-4"
                    >{displayBalance({
                      amount: summary.totalProviderFee,
                      currency: { decimalPlaces: 2, code: summary.currencyCode },
                    })}</td
                  >
                  <td class="px-6 py-4"
                    >{displayBalance({
                      amount: summary.totalPlatformFee - summary.totalProviderFee,
                      currency: { decimalPlaces: 2, code: summary.currencyCode },
                    })}</td
                  >
                </tr>
              {/each}
            </tbody>
          </table>
        {/if}
      </div>

      <br />

      <table class="w-full text-left text-sm text-gray-500 shadow-md dark:text-gray-400">
        <thead
          class="bg-gray-200 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-6 py-3">Id</th>
            <th scope="col" class="px-6 py-3">Type</th>
            <th scope="col" class="px-6 py-3">Business</th>
            <th scope="col" class="px-6 py-3">Amount</th>
            <th scope="col" class="px-6 py-3">Platform Fee</th>
            <th scope="col" class="px-6 py-3">Provider fee</th>
            <th scope="col" class="px-6 py-3">Created at</th>
          </tr>
        </thead>
        <tbody>
          {#if $transactionsQuery.loading}
            Loading...
          {:else if $transactionsQuery.error}
            Error: {$transactionsQuery.error.message}
          {:else}
            {#each transactions.items as transaction}
              <tr class="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
                <td class="px-6 py-4">{transaction.id}</td>
                <th
                  scope="row"
                  class="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  {#if transaction.type === 'PAYMENT'}
                    <span
                      class="mr-2 rounded bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 dark:bg-green-900 dark:text-green-300"
                      >{transaction.type}</span
                    >
                  {:else if transaction.type === 'TRANSFER'}
                    <span
                      class="mr-2 rounded bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800 dark:bg-blue-900 dark:text-blue-300"
                      >{transaction.type}</span
                    >
                  {:else}
                    <span
                      class="mr-2 rounded bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800 dark:bg-gray-700 dark:text-gray-300"
                      >{transaction.type}</span
                    >
                  {/if}
                </th>
                <td class="px-6 py-4 text-left">
                  <a
                    href="/businesses/{transaction.business?.id}"
                    class="font-medium text-blue-600 hover:underline dark:text-blue-500"
                    >{transaction.business?.name}</a
                  >
                </td>
                <td class="px-6 py-4">{displayBalance(transaction.totalAmount)}</td>
                <td class="px-6 py-4">{displayBalance(transaction.taxAmount)}</td>
                <td class="px-6 py-4">{displayBalance(transaction.providerFee)}</td>
                <td class="px-6 py-4">{formatDate(transaction.createdAt)}</td>
                <!-- <td class="px-6 py-4 text-right">
                  <a
                    href="/transactions/{transaction.id}"
                    class="font-medium text-blue-600 dark:text-blue-500 hover:underline">View</a
                  >
                </td> -->
              </tr>
            {/each}
          {/if}
        </tbody>
      </table>

      <div class="spacing-x-3 mt-2 w-full">
        <button
          disabled="{page <= 1 || $transactionsQuery?.loading}"
          on:click="{handlePrevPage}"
          class="w-32 rounded-md bg-gray-200 px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-300 disabled:bg-neutral-200 disabled:text-gray-400 disabled:opacity-40"
          >Previous page</button
        >
        <button
          disabled="{!transactions?.hasNextPage || $transactionsQuery?.loading}"
          on:click="{handleNextPage}"
          class="w-32 rounded-md bg-gray-200 px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-300 disabled:bg-neutral-200 disabled:text-gray-400 disabled:opacity-40"
          >Next page</button
        >
      </div>
    </div>
  </div>
</main>
