import { Client, cacheExchange, fetchExchange } from '@urql/svelte';
import { get } from 'svelte/store';
import { authStore, environment } from './index';

function getUri() {
  switch (get(environment.environment)) {
    case 'local':
      return 'http://localhost:3000/graphql';
    case 'dev':
      return 'https://api-dev.coinless.tips/graphql';
    default:
      return 'https://api.coinless.tips/graphql';
  }
}

export const urglClient = new Client({
  url: getUri(),
  exchanges: [cacheExchange, fetchExchange],
  fetchOptions: () => {
    const token = get(authStore.token);
    return {
      headers: { authorization: token ? `Bearer ${token}` : '' },
    };
  },
});
