import client from 'undefined';
import type {} from '@apollo/client';
import { readable } from 'svelte/store';
import type { Readable } from 'svelte/store';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AcceptTermsResult = {
  __typename?: 'AcceptTermsResult';
  result?: Maybe<Scalars['Boolean']>;
};

export type AccountInfoWithCode = {
  __typename?: 'AccountInfoWithCode';
  avatar?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  firstName: Scalars['String'];
};

export type AccountInformation = {
  avatar?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type AccountInformationForAdmin = AccountInformation & {
  __typename?: 'AccountInformationForAdmin';
  avatar?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  type: UserAccountType;
};

export type AccountInformationForOwner = AccountInformation & {
  __typename?: 'AccountInformationForOwner';
  amountInLastSevenDays?: Maybe<CurrencyAmount>;
  avatar?: Maybe<Scalars['String']>;
  averageRating?: Maybe<AverageRating>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  termsAccepted?: Maybe<Scalars['Boolean']>;
};

export type AccountInformationForRegular = AccountInformation & {
  __typename?: 'AccountInformationForRegular';
  avatar?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type AdminCreateBusinessParams = {
  address: Scalars['String'];
  countryCode: Scalars['String'];
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  type: BusinessType;
};

export type AdminUserAccount = UserAccount & {
  __typename?: 'AdminUserAccount';
  businesses: Array<Maybe<Business>>;
  createdAt: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  type: UserAccountType;
};

export type AuthToken = {
  __typename?: 'AuthToken';
  expiresAt: Scalars['String'];
  id: Scalars['String'];
};

export type AuthenticateInputParams = {
  accountType: UserAccountType;
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  remember?: InputMaybe<Scalars['Boolean']>;
};

export type AuthenticationResult = {
  __typename?: 'AuthenticationResult';
  token: AuthToken;
  userAccount: UserAccount;
};

export type AverageRating = {
  __typename?: 'AverageRating';
  rating: Scalars['Float'];
  reviewsNumber: Scalars['Int'];
};

export type Business = {
  address: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  currencyCode: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  type: BusinessType;
};

export type BusinessAdminMutation = {
  __typename?: 'BusinessAdminMutation';
  createBusiness?: Maybe<CreateBusinessResult>;
  createTeam?: Maybe<CreateTeamResult>;
  generateBusinessCode?: Maybe<BusinessCodeResult>;
  removeMember?: Maybe<RemoveMemberResult>;
  updateBusiness?: Maybe<UpdateBusinessResult>;
  updateMember?: Maybe<UpdateMemberResult>;
  updateTeam?: Maybe<UpdateTeamResult>;
};

export type BusinessAdminMutationCreateBusinessArgs = {
  input: AdminCreateBusinessParams;
};

export type BusinessAdminMutationCreateTeamArgs = {
  input: CreateTeamInputParams;
};

export type BusinessAdminMutationGenerateBusinessCodeArgs = {
  businessId: Scalars['ID'];
};

export type BusinessAdminMutationRemoveMemberArgs = {
  input: RemoveMemberInputParams;
};

export type BusinessAdminMutationUpdateBusinessArgs = {
  input: UpdateBusinessInputParams;
};

export type BusinessAdminMutationUpdateMemberArgs = {
  input: UpdateMemberInputParams;
};

export type BusinessAdminMutationUpdateTeamArgs = {
  input: UpdateTeamInputParams;
};

export type BusinessCodeResult = {
  __typename?: 'BusinessCodeResult';
  code?: Maybe<Scalars['String']>;
};

export type BusinessExternalReview = {
  type: BusinessExternalReviewType;
};

export enum BusinessExternalReviewType {
  Google = 'GOOGLE',
  Tripadvisor = 'TRIPADVISOR',
}

export type BusinessForAdmin = Business & {
  __typename?: 'BusinessForAdmin';
  address: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
  currencyCode: Scalars['String'];
  externalReviews?: Maybe<Array<BusinessExternalReview>>;
  id: Scalars['ID'];
  invites: Array<Invite>;
  logo?: Maybe<Scalars['String']>;
  /** Total members registered for a particular business */
  members: Array<BusinessMember>;
  name: Scalars['String'];
  ownerAccount?: Maybe<AccountInformationForAdmin>;
  ownerId: Scalars['ID'];
  /** Teams registered in a particular business */
  teams: Array<BusinessTeam>;
  themeSettings?: Maybe<BusinessThemeSettings>;
  type: BusinessType;
};

export type BusinessForOwner = Business & {
  __typename?: 'BusinessForOwner';
  address: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  currencyCode: Scalars['String'];
  externalReviews?: Maybe<Array<BusinessExternalReview>>;
  id: Scalars['ID'];
  invites: Array<Invite>;
  logo?: Maybe<Scalars['String']>;
  /** Total members registered for a particular business */
  members: Array<BusinessMember>;
  name: Scalars['String'];
  ownerAccount?: Maybe<UserAccount>;
  ownerId: Scalars['ID'];
  /** Teams registered in a particular business */
  teams: Array<BusinessTeam>;
  themeSettings?: Maybe<BusinessThemeSettings>;
  type: BusinessType;
};

export type BusinessForRegular = Business & {
  __typename?: 'BusinessForRegular';
  address: Scalars['String'];
  /** Business code */
  code?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  currencyCode: Scalars['String'];
  id: Scalars['ID'];
  /** Code for the currently of logged in user in business */
  memberCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** Teams for the currently logged in user */
  teams: Array<BusinessTeam>;
  type: BusinessType;
};

export type BusinessMember = {
  __typename?: 'BusinessMember';
  businessId?: Maybe<Scalars['ID']>;
  code: Scalars['String'];
  userAccount?: Maybe<AccountInformation>;
  userId: Scalars['ID'];
};

export type BusinessOwnerMutation = {
  __typename?: 'BusinessOwnerMutation';
  createBusiness?: Maybe<CreateBusinessResult>;
  createNotification?: Maybe<CreateBusinessNotificationResult>;
  createTeam?: Maybe<CreateTeamResult>;
  deleteLogo?: Maybe<DeleteLogoResult>;
  generateBusinessCode?: Maybe<BusinessCodeResult>;
  /** Invites member to a business */
  inviteMember?: Maybe<InviteMemberResult>;
  removeMember?: Maybe<RemoveMemberResult>;
  updateBusiness?: Maybe<UpdateBusinessResult>;
  updateMember?: Maybe<UpdateMemberResult>;
  updateTeam?: Maybe<UpdateTeamResult>;
  updateThemeSettings?: Maybe<UpdateBusinessThemeSettingsResult>;
};

export type BusinessOwnerMutationCreateBusinessArgs = {
  input: OwnerCreateBusinessParams;
};

export type BusinessOwnerMutationCreateNotificationArgs = {
  input: CreateBusinessNotificationParams;
};

export type BusinessOwnerMutationCreateTeamArgs = {
  input: CreateTeamInputParams;
};

export type BusinessOwnerMutationDeleteLogoArgs = {
  businessId: Scalars['ID'];
};

export type BusinessOwnerMutationGenerateBusinessCodeArgs = {
  businessId: Scalars['ID'];
};

export type BusinessOwnerMutationInviteMemberArgs = {
  input: InviteMemberInputParams;
};

export type BusinessOwnerMutationRemoveMemberArgs = {
  input: RemoveMemberInputParams;
};

export type BusinessOwnerMutationUpdateBusinessArgs = {
  input: UpdateBusinessInputParams;
};

export type BusinessOwnerMutationUpdateMemberArgs = {
  input: UpdateMemberInputParams;
};

export type BusinessOwnerMutationUpdateTeamArgs = {
  input: UpdateTeamInputParams;
};

export type BusinessOwnerMutationUpdateThemeSettingsArgs = {
  input: UpdateBusinessThemeSettingsInput;
};

export type BusinessPageInput = {
  code: Scalars['String'];
};

export type BusinessPageResult = {
  __typename?: 'BusinessPageResult';
  members: Array<AccountInfoWithCode>;
};

export type BusinessReviewsFilter = {
  paidReviews?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type BusinessTeam = {
  __typename?: 'BusinessTeam';
  amountInLastSevenDays?: Maybe<CurrencyAmount>;
  balance: CurrencyAmount;
  code: Scalars['String'];
  directCode: Scalars['String'];
  id: Scalars['ID'];
  leadMember?: Maybe<BusinessMember>;
  leadUserId?: Maybe<Scalars['String']>;
  memberIds: Array<Scalars['String']>;
  members: Array<BusinessMember>;
  name: Scalars['String'];
  pendingBalance: CurrencyAmount;
  transactions: Array<Transaction>;
};

export type BusinessThemeSettings = {
  __typename?: 'BusinessThemeSettings';
  backgroundColor: Scalars['String'];
  foregroundColor: Scalars['String'];
  primaryColor: Scalars['String'];
  theme: Scalars['String'];
};

export enum BusinessType {
  Apartment = 'APARTMENT',
  Bar = 'BAR',
  BarberShop = 'BARBER_SHOP',
  ComputerClub = 'COMPUTER_CLUB',
  Other = 'OTHER',
  Restaurant = 'RESTAURANT',
  Salon = 'SALON',
  ShishaBar = 'SHISHA_BAR',
}

export type BusinessesAdminFilter = {
  businessIds?: InputMaybe<Array<Scalars['ID']>>;
  ownerId?: InputMaybe<Scalars['ID']>;
};

export type BusinessesAdminQuery = {
  __typename?: 'BusinessesAdminQuery';
  businesses?: Maybe<BusinessesPageInfo>;
};

export type BusinessesAdminQueryBusinessesArgs = {
  filter?: InputMaybe<BusinessesAdminFilter>;
  pagination: Pagination;
};

export type BusinessesMutation = {
  __typename?: 'BusinessesMutation';
  forAdmin?: Maybe<BusinessAdminMutation>;
  forOwner?: Maybe<BusinessOwnerMutation>;
};

export type BusinessesOwnerQuery = {
  __typename?: 'BusinessesOwnerQuery';
  business?: Maybe<BusinessForOwner>;
  businesses: Array<Maybe<BusinessForOwner>>;
};

export type BusinessesOwnerQueryBusinessArgs = {
  id: Scalars['ID'];
};

export type BusinessesPageInfo = PageInfo & {
  __typename?: 'BusinessesPageInfo';
  hasNextPage: Scalars['Boolean'];
  items: Array<Business>;
};

export type BusinessesQuery = {
  __typename?: 'BusinessesQuery';
  forAdmin?: Maybe<BusinessesAdminQuery>;
  forOwner?: Maybe<BusinessesOwnerQuery>;
  forRegular?: Maybe<BusinessesRegularQuery>;
  publicBusinessData?: Maybe<PublicBusinessData>;
};

export type BusinessesQueryPublicBusinessDataArgs = {
  businessId: Scalars['ID'];
};

export type BusinessesRegularQuery = {
  __typename?: 'BusinessesRegularQuery';
  businesses: Array<Maybe<BusinessForRegular>>;
};

export type CancelInviteResult = {
  __typename?: 'CancelInviteResult';
  result?: Maybe<Scalars['Boolean']>;
};

export type ChangeForgottenPasswordInput = {
  newPassword: Scalars['String'];
  newPasswordAgain: Scalars['String'];
  passwordSecret: Scalars['ID'];
};

export type ChangeForgottenPasswordResult = {
  __typename?: 'ChangeForgottenPasswordResult';
  result?: Maybe<Scalars['Boolean']>;
};

export type ChangePasswordInput = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordAgain: Scalars['String'];
};

export type ChangePasswordResult = {
  __typename?: 'ChangePasswordResult';
  changed: Scalars['Boolean'];
};

export type CodeMetadata = {
  __typename?: 'CodeMetadata';
  code: Scalars['String'];
  exists: Scalars['Boolean'];
  /** @deprecated Use `type` */
  isGroupCode: Scalars['Boolean'];
  type?: Maybe<CodeType>;
};

export enum CodeType {
  Business = 'BUSINESS',
  Person = 'PERSON',
  Team = 'TEAM',
  TeamDirect = 'TEAM_DIRECT',
}

export type ConnectMeInput = {
  email: Scalars['String'];
  fullName: Scalars['String'];
  jobTitle: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateAdminInviteInput = {
  businessId: Scalars['ID'];
  label: Scalars['String'];
  phoneNumber: Scalars['String'];
  sendSmsNotification?: InputMaybe<Scalars['Boolean']>;
};

export type CreateBusinessNotificationParams = {
  businessId: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
  type: NotificationType;
};

export type CreateBusinessNotificationResult = {
  __typename?: 'CreateBusinessNotificationResult';
  result?: Maybe<Scalars['Boolean']>;
};

export type CreateBusinessResult = {
  __typename?: 'CreateBusinessResult';
  business?: Maybe<Business>;
};

export type CreateIntentInput = {
  amount: Scalars['Int'];
  categories?: InputMaybe<Array<TipCategory>>;
  code: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  includeTax: Scalars['Boolean'];
  rating?: InputMaybe<Scalars['Int']>;
};

export type CreateIntentResult = {
  __typename?: 'CreateIntentResult';
  clientSecret: Scalars['String'];
};

export type CreateInviteResult = {
  __typename?: 'CreateInviteResult';
  invite: Invite;
};

export type CreateOwnerAccountInputParams = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CreateOwnerAccountResult = {
  __typename?: 'CreateOwnerAccountResult';
  userAccount?: Maybe<OwnerUserAccount>;
};

export type CreateRegularAccountInputParams = {
  acceptedTermsAndConditions: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  passwordAgain: Scalars['String'];
  registrationKey: Scalars['ID'];
};

export type CreateReviewInput = {
  categories?: InputMaybe<Array<InputMaybe<TipCategory>>>;
  code: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  rating: Scalars['Int'];
};

export type CreateReviewResult = {
  __typename?: 'CreateReviewResult';
  created?: Maybe<Scalars['Boolean']>;
};

export type CreateTeamInputParams = {
  businessId: Scalars['ID'];
  leadUserId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateTeamResult = {
  __typename?: 'CreateTeamResult';
  team?: Maybe<BusinessTeam>;
};

export type Currency = {
  __typename?: 'Currency';
  code: Scalars['String'];
  decimalPlaces: Scalars['Int'];
};

/** Balance of the entity with a currency */
export type CurrencyAmount = {
  __typename?: 'CurrencyAmount';
  amount: Scalars['Float'];
  currency: Currency;
};

export type CurrencyFeeTariff = {
  __typename?: 'CurrencyFeeTariff';
  fixedFee: Scalars['Float'];
  validUpToAmount?: Maybe<Scalars['Float']>;
  variableFee: Scalars['Float'];
};

export type CurrencyMetaResult = {
  __typename?: 'CurrencyMetaResult';
  feeTariffs: Array<CurrencyFeeTariff>;
  minimalTip: Scalars['Float'];
};

export type DeleteLogoResult = {
  __typename?: 'DeleteLogoResult';
  result: Scalars['Boolean'];
};

export type DistributeTeamFundsInput = {
  amount: Scalars['Int'];
  teamId: Scalars['ID'];
};

export type DistributeTeamFundsResult = {
  __typename?: 'DistributeTeamFundsResult';
  accepted?: Maybe<Scalars['Boolean']>;
};

export type ExternalReviewInput = {
  type: BusinessExternalReviewType;
  url: Scalars['String'];
};

export type GoogleExternalReview = BusinessExternalReview & {
  __typename?: 'GoogleExternalReview';
  type: BusinessExternalReviewType;
  url: Scalars['String'];
};

export type GroupData = {
  __typename?: 'GroupData';
  members: Array<AccountInfoWithCode>;
};

export type GroupPageInput = {
  code: Scalars['String'];
};

export type GroupPageResult = {
  __typename?: 'GroupPageResult';
  group?: Maybe<GroupData>;
};

export type Invite = {
  __typename?: 'Invite';
  businessData?: Maybe<PublicBusinessData>;
  businessId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  expiresAt: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  phoneNumber: Scalars['String'];
  state: InviteState;
  type?: Maybe<InviteType>;
};

export enum InviteDecision {
  Accept = 'ACCEPT',
  Decline = 'DECLINE',
}

export type InviteMemberInputParams = {
  businessId: Scalars['ID'];
  label: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type InviteMemberResult = {
  __typename?: 'InviteMemberResult';
  invite?: Maybe<Invite>;
};

export enum InviteState {
  Accepted = 'ACCEPTED',
  /** Invite has been cancelled */
  Cancelled = 'CANCELLED',
  /** Invite has been declined by the recipient */
  Declined = 'DECLINED',
  /** Invite has expires and is no longer valid */
  Expired = 'EXPIRED',
  /** Invite is active */
  Pending = 'PENDING',
}

export enum InviteType {
  /** Invite that invites USER into the business */
  User = 'USER',
}

export type InvitesAdminFilter = {
  businessIds?: InputMaybe<Array<Scalars['ID']>>;
  states?: InputMaybe<Array<InviteState>>;
};

export type InvitesAdminMutation = {
  __typename?: 'InvitesAdminMutation';
  cancelInvite?: Maybe<CancelInviteResult>;
  createInvite?: Maybe<CreateInviteResult>;
};

export type InvitesAdminMutationCancelInviteArgs = {
  inviteId: Scalars['ID'];
};

export type InvitesAdminMutationCreateInviteArgs = {
  input: CreateAdminInviteInput;
};

export type InvitesAdminQuery = {
  __typename?: 'InvitesAdminQuery';
  invites?: Maybe<InvitesPageInfo>;
};

export type InvitesAdminQueryInvitesArgs = {
  filter?: InputMaybe<InvitesAdminFilter>;
  pagination: Pagination;
};

export type InvitesMutation = {
  __typename?: 'InvitesMutation';
  forAdmin?: Maybe<InvitesAdminMutation>;
  forOwner?: Maybe<InvitesOwnerMutation>;
  forRegular?: Maybe<InvitesRegularMutation>;
};

export type InvitesOwnerMutation = {
  __typename?: 'InvitesOwnerMutation';
  cancelInvite?: Maybe<CancelInviteResult>;
};

export type InvitesOwnerMutationCancelInviteArgs = {
  inviteId: Scalars['ID'];
};

export type InvitesPageInfo = PageInfo & {
  __typename?: 'InvitesPageInfo';
  hasNextPage: Scalars['Boolean'];
  items: Array<Invite>;
};

export type InvitesQuery = {
  __typename?: 'InvitesQuery';
  forAdmin?: Maybe<InvitesAdminQuery>;
  forRegular?: Maybe<InvitesRegularQuery>;
  /** Gets information about an invite by registration key */
  inviteByRegistrationKey?: Maybe<Invite>;
};

export type InvitesQueryInviteByRegistrationKeyArgs = {
  registrationKey: Scalars['String'];
};

export type InvitesRegularMutation = {
  __typename?: 'InvitesRegularMutation';
  respondToInvite?: Maybe<ResponseToInviteResult>;
};

export type InvitesRegularMutationRespondToInviteArgs = {
  input: RespondToInviteInput;
};

export type InvitesRegularQuery = {
  __typename?: 'InvitesRegularQuery';
  pendingInvites: Array<Invite>;
};

export type LogoutInputParams = {
  token: Scalars['String'];
};

export type LogoutResult = {
  __typename?: 'LogoutResult';
  result?: Maybe<Scalars['Boolean']>;
};

export type MeQuery = {
  __typename?: 'MeQuery';
  userAccount?: Maybe<UserAccount>;
};

export type Mutation = {
  __typename?: 'Mutation';
  businesses?: Maybe<BusinessesMutation>;
  connectMe?: Maybe<Scalars['Boolean']>;
  invites?: Maybe<InvitesMutation>;
  payments?: Maybe<PaymentsMutation>;
  reviews?: Maybe<ReviewsMutation>;
  userAccounts?: Maybe<UserAccountsMutation>;
};

export type MutationConnectMeArgs = {
  input: ConnectMeInput;
};

export enum NotificationType {
  Telegram = 'TELEGRAM',
  Whatsapp = 'WHATSAPP',
}

export type OwnerCreateBusinessParams = {
  address: Scalars['String'];
  countryCode: Scalars['String'];
  name: Scalars['String'];
  type: BusinessType;
};

export type OwnerUserAccount = UserAccount & {
  __typename?: 'OwnerUserAccount';
  businesses: Array<Maybe<Business>>;
  createdAt: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  termsAccepted?: Maybe<Scalars['Boolean']>;
  type: UserAccountType;
};

export type PageInfo = {
  hasNextPage: Scalars['Boolean'];
};

export type Pagination = {
  page: Scalars['Int'];
  /** Records per page */
  recordsPerPage: Scalars['Int'];
};

export type PaymentsMutation = {
  __typename?: 'PaymentsMutation';
  createIntent?: Maybe<CreateIntentResult>;
  forRegular?: Maybe<PaymentsRegularMutation>;
};

export type PaymentsMutationCreateIntentArgs = {
  input: CreateIntentInput;
};

export type PaymentsQuery = {
  __typename?: 'PaymentsQuery';
  currencyMeta?: Maybe<CurrencyMetaResult>;
};

export type PaymentsQueryCurrencyMetaArgs = {
  currencyCode: Scalars['String'];
};

export type PaymentsRegularMutation = {
  __typename?: 'PaymentsRegularMutation';
  distributeTeamFunds?: Maybe<DistributeTeamFundsResult>;
  payout?: Maybe<PayoutResult>;
  transferAvailableFunds?: Maybe<TransferAvailableFundsResult>;
  transferTeamFunds?: Maybe<TransferTeamFundsResult>;
};

export type PaymentsRegularMutationDistributeTeamFundsArgs = {
  input: DistributeTeamFundsInput;
};

export type PaymentsRegularMutationPayoutArgs = {
  input: PayoutInput;
};

export type PaymentsRegularMutationTransferTeamFundsArgs = {
  input: TransferTeamFundsInput;
};

export type PayoutInput = {
  amount: Scalars['Int'];
};

export type PayoutResult = {
  __typename?: 'PayoutResult';
  arrivalDate?: Maybe<Scalars['String']>;
};

export type PublicBusinessData = {
  __typename?: 'PublicBusinessData';
  address: Scalars['String'];
  currencyCode: Scalars['String'];
  externalReviews?: Maybe<Array<BusinessExternalReview>>;
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  themeSettings?: Maybe<BusinessThemeSettings>;
  type: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  businessPage?: Maybe<BusinessPageResult>;
  businesses?: Maybe<BusinessesQuery>;
  codeMetadata?: Maybe<CodeMetadata>;
  groupPage?: Maybe<GroupPageResult>;
  invites?: Maybe<InvitesQuery>;
  me?: Maybe<MeQuery>;
  payments?: Maybe<PaymentsQuery>;
  reviews?: Maybe<ReviewsQuery>;
  statistics?: Maybe<StatisticsQuery>;
  tipPage?: Maybe<TipPageResult>;
  transactions?: Maybe<TransactionsQuery>;
  userAccounts?: Maybe<UserAccountsQuery>;
};

export type QueryBusinessPageArgs = {
  input: BusinessPageInput;
};

export type QueryCodeMetadataArgs = {
  code: Scalars['String'];
};

export type QueryGroupPageArgs = {
  input: GroupPageInput;
};

export type QueryTipPageArgs = {
  input: TipPageInput;
};

/** Aggregation link of transactions and reviews for a single user */
export type RatingLink = {
  __typename?: 'RatingLink';
  business?: Maybe<Business>;
  businessId: Scalars['ID'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  review?: Maybe<Review>;
  reviewId?: Maybe<Scalars['ID']>;
  transaction?: Maybe<Transaction>;
  transactionId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
};

export type RatingLinksPageInfo = PageInfo & {
  __typename?: 'RatingLinksPageInfo';
  hasNextPage: Scalars['Boolean'];
  items: Array<RatingLink>;
};

export type RegularUserAccount = UserAccount & {
  __typename?: 'RegularUserAccount';
  avatar?: Maybe<Scalars['String']>;
  balances?: Maybe<UserBalances>;
  createdAt: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  reviews: Array<Maybe<Review>>;
  settings?: Maybe<UserSettings>;
  stripeData?: Maybe<StripeData>;
  stripeSetupLink?: Maybe<StripeSetupLink>;
  /** Teams user is within in, only REGULAR user account can be withing a team */
  teams: Array<Maybe<BusinessTeam>>;
  type: UserAccountType;
};

export type RemoveMemberInputParams = {
  businessId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type RemoveMemberResult = {
  __typename?: 'RemoveMemberResult';
  result?: Maybe<Scalars['Boolean']>;
};

export type ResetPasswordInput = {
  email?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  type: UserAccountType;
};

export type ResetPasswordResult = {
  __typename?: 'ResetPasswordResult';
  result: Scalars['Boolean'];
};

export type RespondToInviteInput = {
  decision: InviteDecision;
  inviteId: Scalars['ID'];
};

export type ResponseToInviteResult = {
  __typename?: 'ResponseToInviteResult';
  result?: Maybe<Scalars['Boolean']>;
};

export type RevenueStatisticsResult = {
  __typename?: 'RevenueStatisticsResult';
  month: CurrencyAmount;
  today: CurrencyAmount;
  week: CurrencyAmount;
};

export type Review = {
  __typename?: 'Review';
  businessId: Scalars['String'];
  categories?: Maybe<Array<TipCategory>>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  rating: Scalars['Int'];
  team?: Maybe<ReviewTeamInfo>;
  teamId?: Maybe<Scalars['String']>;
  userAccount?: Maybe<AccountInformation>;
  userId?: Maybe<Scalars['ID']>;
  withTip?: Maybe<Scalars['Boolean']>;
};

export type ReviewTeamInfo = {
  __typename?: 'ReviewTeamInfo';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ReviewsForBusinessPageInfo = PageInfo & {
  __typename?: 'ReviewsForBusinessPageInfo';
  hasNextPage: Scalars['Boolean'];
  items: Array<Review>;
};

export type ReviewsMutation = {
  __typename?: 'ReviewsMutation';
  createReview?: Maybe<CreateReviewResult>;
};

export type ReviewsMutationCreateReviewArgs = {
  input: CreateReviewInput;
};

export type ReviewsOwnerQuery = {
  __typename?: 'ReviewsOwnerQuery';
  reviewsForBusiness?: Maybe<ReviewsForBusinessPageInfo>;
};

export type ReviewsOwnerQueryReviewsForBusinessArgs = {
  businessId: Scalars['ID'];
  filter?: InputMaybe<BusinessReviewsFilter>;
  pagination: Pagination;
};

export type ReviewsQuery = {
  __typename?: 'ReviewsQuery';
  forOwner?: Maybe<ReviewsOwnerQuery>;
  forRegular?: Maybe<ReviewsRegularQuery>;
};

export type ReviewsRegularQuery = {
  __typename?: 'ReviewsRegularQuery';
  averageRating?: Maybe<AverageRating>;
  ratingLinks?: Maybe<RatingLinksPageInfo>;
};

export type ReviewsRegularQueryRatingLinksArgs = {
  pagination: Pagination;
};

export type Series = {
  __typename?: 'Series';
  data: Array<SeriesData>;
  name: Scalars['String'];
};

export type SeriesData = TimeSeriesTipValue;

export type SeriesResult = {
  series: Array<Series>;
};

export type StatisticsQuery = {
  __typename?: 'StatisticsQuery';
  forRegular?: Maybe<StatisticsRegularQuery>;
};

export type StatisticsRegularQuery = {
  __typename?: 'StatisticsRegularQuery';
  tipSeries?: Maybe<TipSeriesResult>;
};

export type StatisticsRegularQueryTipSeriesArgs = {
  input?: InputMaybe<TipSeriesInput>;
};

export type StripeData = {
  __typename?: 'StripeData';
  accountCreated: Scalars['Boolean'];
  accountId: Scalars['String'];
  balance?: Maybe<CurrencyAmount>;
  informationDeadLine?: Maybe<Scalars['Int']>;
  informationNeeded: Scalars['Boolean'];
  payoutsEnabled: Scalars['Boolean'];
  pendingBalance?: Maybe<CurrencyAmount>;
  transitBalance?: Maybe<CurrencyAmount>;
  verificationInProgress: Scalars['Boolean'];
};

export type StripeSetupLink = {
  __typename?: 'StripeSetupLink';
  url?: Maybe<Scalars['String']>;
};

export type TimeSeriesTipValue = {
  __typename?: 'TimeSeriesTipValue';
  averageTip: Scalars['Float'];
  date: Scalars['String'];
  timestamp: Scalars['String'];
  tipsNumber: Scalars['Int'];
  totalTips: Scalars['Float'];
};

export enum TipCategory {
  Beard = 'BEARD',
  BedComfort = 'BED_COMFORT',
  Environment = 'ENVIRONMENT',
  Food = 'FOOD',
  Haircut = 'HAIRCUT',
  Service = 'SERVICE',
  Shisha = 'SHISHA',
}

export type TipPageInput = {
  code: Scalars['String'];
};

export type TipPageResult = {
  __typename?: 'TipPageResult';
  account?: Maybe<AccountInformation>;
  business?: Maybe<PublicBusinessData>;
};

export type TipSeriesInput = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type TipSeriesResult = SeriesResult & {
  __typename?: 'TipSeriesResult';
  series: Array<Series>;
};

export type Transaction = {
  __typename?: 'Transaction';
  business?: Maybe<Business>;
  /** Payouts do not have a business Id */
  businessId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  externalId: Scalars['ID'];
  /** When transaction has entered a final state */
  finishedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initiatorUser?: Maybe<AccountInformation>;
  /** Id of the user who initiated a transaction */
  initiatorUserId?: Maybe<Scalars['ID']>;
  providerFee?: Maybe<CurrencyAmount>;
  receiverUser?: Maybe<AccountInformation>;
  /** Id of the user for whom the payment was made */
  receiverUserId?: Maybe<Scalars['ID']>;
  state: Scalars['String'];
  taxAmount: CurrencyAmount;
  team?: Maybe<BusinessTeam>;
  /** Id of the team if transaction is of type TRANSFER */
  teamId?: Maybe<Scalars['ID']>;
  totalAmount: CurrencyAmount;
  type: TransactionType;
};

export enum TransactionState {
  Finished = 'FINISHED',
  Pending = 'PENDING',
}

export enum TransactionType {
  Payment = 'PAYMENT',
  Payout = 'PAYOUT',
  Transfer = 'TRANSFER',
}

export type TransactionsAdminFilter = {
  type?: InputMaybe<TransactionType>;
};

export type TransactionsAdminQuery = {
  __typename?: 'TransactionsAdminQuery';
  transactions?: Maybe<TransactionsPageInfo>;
  transactionsSummary?: Maybe<TransactionsSummaryResult>;
};

export type TransactionsAdminQueryTransactionsArgs = {
  filter?: InputMaybe<TransactionsAdminFilter>;
  pagination: Pagination;
};

export type TransactionsAdminQueryTransactionsSummaryArgs = {
  filter: TransactionsSummaryFilter;
};

export type TransactionsOwnerFilter = {
  businessId?: InputMaybe<Scalars['ID']>;
  teamId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<TransactionType>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type TransactionsOwnerQuery = {
  __typename?: 'TransactionsOwnerQuery';
  transactions?: Maybe<TransactionsPageInfo>;
};

export type TransactionsOwnerQueryTransactionsArgs = {
  filter?: InputMaybe<TransactionsOwnerFilter>;
  pagination: Pagination;
};

export type TransactionsPageInfo = PageInfo & {
  __typename?: 'TransactionsPageInfo';
  hasNextPage: Scalars['Boolean'];
  items: Array<Transaction>;
};

export type TransactionsQuery = {
  __typename?: 'TransactionsQuery';
  forAdmin?: Maybe<TransactionsAdminQuery>;
  forOwner?: Maybe<TransactionsOwnerQuery>;
  forRegular?: Maybe<TransactionsRegularQuery>;
};

export type TransactionsRegularFilter = {
  forWallet?: InputMaybe<Scalars['Boolean']>;
  teamId?: InputMaybe<Scalars['String']>;
};

export type TransactionsRegularQuery = {
  __typename?: 'TransactionsRegularQuery';
  revenueStatistics?: Maybe<RevenueStatisticsResult>;
  transactions?: Maybe<TransactionsPageInfo>;
};

export type TransactionsRegularQueryTransactionsArgs = {
  filter?: InputMaybe<TransactionsRegularFilter>;
  pagination: Pagination;
};

export type TransactionsSummary = {
  __typename?: 'TransactionsSummary';
  currencyCode: Scalars['String'];
  numberOfTransactions: Scalars['Int'];
  totalPlatformFee: Scalars['Float'];
  totalProviderFee: Scalars['Float'];
  totalSum: Scalars['Float'];
};

export type TransactionsSummaryFilter = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type TransactionsSummaryResult = {
  __typename?: 'TransactionsSummaryResult';
  summaries?: Maybe<Array<TransactionsSummary>>;
};

export type TransferAvailableFundsResult = {
  __typename?: 'TransferAvailableFundsResult';
  accepted?: Maybe<Scalars['Boolean']>;
};

export type TransferTeamFundsInput = {
  amount: Scalars['Int'];
  targetUserId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type TransferTeamFundsResult = {
  __typename?: 'TransferTeamFundsResult';
  accepted?: Maybe<Scalars['Boolean']>;
};

export type TripadvisorExternalReview = BusinessExternalReview & {
  __typename?: 'TripadvisorExternalReview';
  type: BusinessExternalReviewType;
  url: Scalars['String'];
};

export type UpdateBusinessInputParams = {
  address: Scalars['String'];
  businessId: Scalars['ID'];
  externalReviews?: InputMaybe<Array<ExternalReviewInput>>;
  name: Scalars['String'];
};

export type UpdateBusinessResult = {
  __typename?: 'UpdateBusinessResult';
  business?: Maybe<Business>;
};

export type UpdateBusinessThemeSettingsInput = {
  backgroundColor: Scalars['String'];
  businessId: Scalars['ID'];
  foregroundColor: Scalars['String'];
  primaryColor: Scalars['String'];
  theme: Scalars['String'];
};

export type UpdateBusinessThemeSettingsResult = {
  __typename?: 'UpdateBusinessThemeSettingsResult';
  result?: Maybe<Scalars['Boolean']>;
};

export type UpdateMemberInputParams = {
  businessId: Scalars['ID'];
  memberId: Scalars['ID'];
  /** Fill to change group ID */
  teamId: Scalars['ID'];
};

export type UpdateMemberResult = {
  __typename?: 'UpdateMemberResult';
  result?: Maybe<Scalars['Boolean']>;
};

export type UpdateTeamInputParams = {
  businessId: Scalars['ID'];
  leadUserId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  teamId: Scalars['ID'];
};

export type UpdateTeamResult = {
  __typename?: 'UpdateTeamResult';
  completed?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserInformationResult = {
  __typename?: 'UpdateUserInformationResult';
  updated: Scalars['Boolean'];
};

export type UpdateUserSettingsInput = {
  monthlyPayoutReportsEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateUserSettingsResult = {
  __typename?: 'UpdateUserSettingsResult';
  updated: Scalars['Boolean'];
};

export type UserAccount = {
  createdAt: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  /** Type of the user account */
  type: UserAccountType;
};

export enum UserAccountType {
  /** Administrator of the service */
  Admin = 'ADMIN',
  /** Owner of the business */
  Owner = 'OWNER',
  /** Regular user of the server */
  Regular = 'REGULAR',
}

export type UserAccountsAdminFilter = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UserAccountsAdminMutation = {
  __typename?: 'UserAccountsAdminMutation';
  createOwnerAccount?: Maybe<CreateOwnerAccountResult>;
};

export type UserAccountsAdminMutationCreateOwnerAccountArgs = {
  input: CreateOwnerAccountInputParams;
};

export type UserAccountsAdminQuery = {
  __typename?: 'UserAccountsAdminQuery';
  userAccount?: Maybe<UserAccount>;
  userAccounts?: Maybe<UserAccountsInfoPageInfo>;
};

export type UserAccountsAdminQueryUserAccountArgs = {
  id: Scalars['ID'];
};

export type UserAccountsAdminQueryUserAccountsArgs = {
  filter?: InputMaybe<UserAccountsAdminFilter>;
  pagination: Pagination;
};

export type UserAccountsInfoPageInfo = PageInfo & {
  __typename?: 'UserAccountsInfoPageInfo';
  hasNextPage: Scalars['Boolean'];
  items: Array<AccountInformationForAdmin>;
};

export type UserAccountsMutation = {
  __typename?: 'UserAccountsMutation';
  authenticate?: Maybe<AuthenticationResult>;
  changeForgottenPassword?: Maybe<ChangeForgottenPasswordResult>;
  /** Creates a regular account, requires a PENDING invite to be present for successful registration */
  createRegularAccount?: Maybe<AuthenticationResult>;
  forAdmin?: Maybe<UserAccountsAdminMutation>;
  forOwner?: Maybe<UserAccountsOwnerMutation>;
  forRegular?: Maybe<UserAccountsRegularMutation>;
  logout?: Maybe<LogoutResult>;
  resetPassword?: Maybe<ResetPasswordResult>;
};

export type UserAccountsMutationAuthenticateArgs = {
  input: AuthenticateInputParams;
};

export type UserAccountsMutationChangeForgottenPasswordArgs = {
  input: ChangeForgottenPasswordInput;
};

export type UserAccountsMutationCreateRegularAccountArgs = {
  input: CreateRegularAccountInputParams;
};

export type UserAccountsMutationLogoutArgs = {
  input: LogoutInputParams;
};

export type UserAccountsMutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type UserAccountsOwnerMutation = {
  __typename?: 'UserAccountsOwnerMutation';
  acceptTerms?: Maybe<AcceptTermsResult>;
  changePassword?: Maybe<ChangePasswordResult>;
  updateUserInformation?: Maybe<UpdateUserInformationResult>;
};

export type UserAccountsOwnerMutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type UserAccountsOwnerMutationUpdateUserInformationArgs = {
  input: UserOwnerInformationInput;
};

export type UserAccountsQuery = {
  __typename?: 'UserAccountsQuery';
  forAdmin?: Maybe<UserAccountsAdminQuery>;
};

export type UserAccountsRegularMutation = {
  __typename?: 'UserAccountsRegularMutation';
  changePassword?: Maybe<ChangePasswordResult>;
  updateSettings?: Maybe<UpdateUserSettingsResult>;
  updateUserInformation?: Maybe<UpdateUserInformationResult>;
};

export type UserAccountsRegularMutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type UserAccountsRegularMutationUpdateSettingsArgs = {
  input: UpdateUserSettingsInput;
};

export type UserAccountsRegularMutationUpdateUserInformationArgs = {
  input: UserInformationInput;
};

export type UserBalances = {
  __typename?: 'UserBalances';
  availableBalances?: Maybe<Array<CurrencyAmount>>;
  pendingBalances?: Maybe<Array<CurrencyAmount>>;
  transitBalances?: Maybe<Array<CurrencyAmount>>;
};

export type UserInformationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UserOwnerInformationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UserSettings = {
  __typename?: 'UserSettings';
  monthlyPayoutReportsEnabled?: Maybe<Scalars['Boolean']>;
};
