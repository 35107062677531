<script lang="ts">
import { onMount } from 'svelte';
import BusinessScreen from './BusinessScreen.svelte';
import BusinessSettings from './BusinessSettings.svelte';
import { Tab, initTE } from 'tw-elements';

onMount(() => {
  initTE({ Tab });
});
</script>

<main class="mt-2 flex flex-col items-center">
  <div class="w-full md:w-4/6 lg:w-3/6">
    <ul
      class="mb-5 flex list-none flex-row flex-wrap border-b-0 pl-0"
      role="tablist"
      data-te-nav-ref
    >
      <li role="presentation">
        <a
          href="#tabs-home"
          class="my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
          data-te-toggle="pill"
          data-te-target="#tabs-info"
          data-te-nav-active
          role="tab"
          aria-controls="tabs-info"
          aria-selected="true">Business info</a
        >
      </li>
      <li role="presentation">
        <a
          href="#tabs-profile"
          class="focus:border-transparen my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
          data-te-toggle="pill"
          data-te-target="#tabs-settings"
          role="tab"
          aria-controls="tabs-settings"
          aria-selected="false">Business settings</a
        >
      </li>
    </ul>

    <!--Tabs content-->
    <div class="mb-6">
      <div
        class="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
        id="tabs-info"
        role="tabpanel"
        aria-labelledby="tabs-infp"
        data-te-tab-active
      >
        <BusinessScreen />
      </div>
      <div
        class="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
        id="tabs-settings"
        role="tabpanel"
        aria-labelledby="tabs-profile-tab"
      >
        <BusinessSettings />
      </div>
    </div>
  </div>
</main>
