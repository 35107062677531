<script lang="ts">
import './global.css';
import Router from './components/Router.svelte';
import { setClient } from 'svelte-apollo';
import { appolo } from './stores';
import { setContextClient } from '@urql/svelte';
import { urglClient } from './stores/urglClient';

appolo.client.subscribe((client) => {
  setClient(client);
});
setContextClient(urglClient);
</script>

<Router />
