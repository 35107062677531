<script lang="ts">
import { createForm } from 'svelte-forms-lib';
import * as yup from 'yup';
import { REQUIRED_FIELD } from '../../constants/messages';
import Loader from '../../components/Loader.svelte';
import { mutation } from 'svelte-apollo';
import { gql } from '@apollo/client/core';

export let refresh;
export let businessId;

let loading = false;

const createTeamMutation = mutation(gql`
  mutation createTeam($businessId: ID!, $name: String!, $leadUserId: String) {
    businesses {
      forAdmin {
        createTeam(input: { businessId: $businessId, name: $name, leadUserId: $leadUserId }) {
          team {
            id
          }
        }
      }
    }
  }
`);

let error;

const { form, errors, handleSubmit, validateField } = createForm({
  initialValues: {
    name: '',
  },
  validationSchema: yup.object().shape({
    name: yup.string().min(1).max(30).required(REQUIRED_FIELD),
  }),
  onSubmit: async (params) => {
    loading = true;
    try {
      const result = await createTeamMutation({
        variables: {
          ...params,
          businessId,
        },
      });

      if (result.errors) {
        error = result.errors[0].message;
      } else {
        document.getElementById('close-button-create-team').click();
        await refresh();
      }
    } catch (err) {
      error = err.message;
      console.log(err);
    } finally {
      loading = false;
    }
  },
});

const inputClass = `"
      form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      invalid:border-pink-500 invalid:text-pink-600
    "`;
</script>

<div
  data-te-modal-init
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="createTeamModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div
    data-te-modal-dialog-ref
    class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px]"
  >
    <form class:error="{!!$errors.name}" on:submit="{handleSubmit}">
      <div
        class="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none"
      >
        <div
          class="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4"
        >
          <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">
            New team
          </h5>
          <button
            type="button"
            class="btn-close box-content h-4 w-4 rounded-none border-none p-1 text-black opacity-50 hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        {#if error}
          <div class="mt-2 flex justify-center">
            <div
              class="mb-3 mb-4 w-5/6 rounded-lg bg-red-100 px-6 py-5 text-base text-red-700"
              role="alert"
            >
              {error}
            </div>
          </div>
        {/if}
        <div class="modal-body relative space-y-2 p-4">
          <input
            type="text"
            class="{inputClass}"
            id="teamName"
            placeholder="Team name"
            bind:value="{$form.name}"
            on:blur="{() => !!$form.name?.length && validateField('name')}"
            autocomplete="off"
          />
          <span class="text-red-700">{$errors.name}</span>
        </div>
        <div
          class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-gray-200 p-4"
        >
          <button
            id="close-button-create-team"
            type="button"
            class="rounded
            bg-gray-500
            px-6
            py-2.5
            text-xs
            font-medium
            uppercase
            leading-tight
            text-white
            shadow-md
            transition duration-150
            ease-in-out hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700
            focus:shadow-lg focus:outline-none
            focus:ring-0
            active:bg-gray-800
            active:shadow-lg"
            data-te-modal-dismiss>Close</button
          >
          <button
            type="submit"
            class="ml-1
              rounded
              bg-green-600
              px-6
              py-2.5
              text-xs
              font-medium
              uppercase
              leading-tight
              text-white
              shadow-md transition
              duration-150 ease-in-out hover:bg-green-700 hover:shadow-lg
              focus:bg-green-700 focus:shadow-lg
              focus:outline-none
              focus:ring-0
              active:bg-green-800
              active:shadow-lg"
          >
            {#if loading}
              <Loader color="#fff" />
            {:else}
              Create team
            {/if}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
