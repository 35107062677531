<style lang="scss">
.loader {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<script lang="ts">
import { Router, Route } from 'svelte-navigator';
import { authStore } from '../stores';
import LoginScreen from '../screens/LoginScreen.svelte';
import HomeScreen from '../screens/BusinessesScreen.svelte';
import UsersScreen from '../screens/users/UsersScreen.svelte';
import UserScreen from '../screens/user/UserScreen.svelte';
import TransactionsScreen from '../screens/transactions/TransactionsScreen.svelte';
import BusinessScreen from '../screens/business/BusinessRouter.svelte';
import Loader from './Loader.svelte';
import RouterPage from './RouterPage.svelte';

const { isAuthenticated, getMeStatus } = authStore;
</script>

<Router>
  {#if $isAuthenticated}
    <Route path="/">
      <RouterPage>
        <HomeScreen />
      </RouterPage>
    </Route>
    <Route path="/users">
      <RouterPage>
        <UsersScreen />
      </RouterPage>
    </Route>
    <Route path="/transactions">
      <RouterPage>
        <TransactionsScreen />
      </RouterPage>
    </Route>
    <Route path="/users/:id">
      <RouterPage>
        <UserScreen />
      </RouterPage>
    </Route>
    <Route path="/businesses/:id">
      <RouterPage>
        <BusinessScreen />
      </RouterPage>
    </Route>
    <Route>404</Route>
  {:else if $getMeStatus === 'fetching'}
    <Route path="/" primary="{false}">
      <div class="loader">
        <Loader size="4rem" />
      </div>
    </Route>
  {:else}
    <Route path="/" primary="{false}">
      <LoginScreen />
    </Route>
    <Route><LoginScreen /></Route>
  {/if}
</Router>
