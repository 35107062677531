import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client/core';
import { authStore, environment } from './index';
import { writable, get } from 'svelte/store';
import { setContext } from '@apollo/client/link/context';

const authLink = setContext((_, { headers }) => {
  const token = get(authStore.token);
  return {
    uri: getUri(),
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

function getUri() {
  switch (get(environment.environment)) {
    case 'local':
      return 'http://localhost:3000/graphql';
    case 'dev':
      return 'https://api-dev.coinless.tips/graphql';
    default:
      return 'https://api.coinless.tips/graphql';
  }
}

const httpLink = new HttpLink({
  uri: 'https://api-dev.coinless.tips/graphql',
});

const cache = new InMemoryCache();

const newClient = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
});

const client = writable(newClient);

export default {
  client,
};
