<script lang="ts">
import { createForm } from 'svelte-forms-lib';
import * as yup from 'yup';
import { INVALID_EMAIL, REQUIRED_FIELD } from '../../constants/messages';
import Loader from '../../components/Loader.svelte';
import { mutation } from 'svelte-apollo';
import { gql } from '@apollo/client/core';
import { navigate } from 'svelte-navigator';
import { Modal, initTE } from 'tw-elements';
import { onMount } from 'svelte';

onMount(() => {
  initTE({ Modal });
});

let loading = false;

const createOwnerMutation = mutation(gql`
  mutation createOwner(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
  ) {
    userAccounts {
      forAdmin {
        createOwnerAccount(
          input: {
            firstName: $firstName
            lastName: $lastName
            email: $email
            phoneNumber: $phoneNumber
          }
        ) {
          userAccount {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`);

let error;

const { form, errors, handleSubmit, validateField } = createForm({
  initialValues: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  validationSchema: yup.object().shape({
    firstName: yup.string().required(REQUIRED_FIELD),
    lastName: yup.string().required(REQUIRED_FIELD),
    email: yup.string().email(INVALID_EMAIL).required(REQUIRED_FIELD),
    phoneNumber: yup.string().min(3).required(REQUIRED_FIELD),
  }),
  onSubmit: async (params) => {
    loading = true;
    try {
      const result = await createOwnerMutation({
        variables: params,
      });

      if (result.errors) {
        error = result.errors[0].message;
      } else {
        document.getElementById('close-button').click();
        navigate(
          `/users/${
            (result?.data as any)?.userAccounts?.forAdmin?.createOwnerAccount?.userAccount?.id
          }`,
        );
      }
    } catch (err) {
      error = err.message;
      console.log(err);
    } finally {
      loading = false;
    }
  },
});

const inputClass = `"
    form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
    invalid:border-pink-500 invalid:text-pink-600
  "`;
</script>

<button
  type="button"
  class="rounded
      bg-blue-600
      px-6
      py-2.5
      text-xs
      font-medium
      uppercase
      leading-tight
      text-white
      shadow-md
      transition duration-150
      ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700
      focus:shadow-lg focus:outline-none
      focus:ring-0
      active:bg-blue-800
      active:shadow-lg"
  data-te-toggle="modal"
  data-te-target="#exampleModal"
>
  Create owner account
</button>

<form class:error="{!!$errors.email}" on:submit="{handleSubmit}" class="space-y-2">
  <div
    data-te-modal-init
    class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      data-te-modal-dialog-ref
      class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px]"
    >
      <div
        class="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none"
      >
        <div
          class="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4"
        >
          <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">
            Create owner account
          </h5>
          <button
            type="button"
            class="btn-close box-content h-4 w-4 rounded-none border-none p-1 text-black opacity-50 hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        {#if error}
          <div class="mt-2 flex justify-center">
            <div
              class="mb-3 mb-4 w-5/6 rounded-lg bg-red-100 px-6 py-5 text-base text-red-700"
              role="alert"
            >
              {error}
            </div>
          </div>
        {/if}
        <div class="modal-body relative space-y-3 p-4">
          <input
            type="text"
            class="{inputClass}"
            id="firstName"
            placeholder="First name"
            bind:value="{$form.firstName}"
            autocomplete="off"
            on:blur="{() => !!$form.firstName?.length && validateField('firstName')}"
          />
          <span class="text-red-700">{$errors.firstName}</span>
          <input
            type="text"
            class="{inputClass}"
            id="lastName"
            placeholder="Last name"
            bind:value="{$form.lastName}"
            autocomplete="off"
            on:blur="{() => !!$form.lastName?.length && validateField('lastName')}"
          />
          <span class="text-red-700">{$errors.lastName}</span>
          <input
            type="email"
            class="{inputClass}"
            id="email"
            placeholder="Email"
            bind:value="{$form.email}"
            autocomplete="off"
            on:blur="{() => !!$form.email?.length && validateField('email')}"
          />
          <span class="text-red-700">{$errors.email}</span>
          <input
            type="text"
            class="{inputClass}"
            bind:value="{$form.phoneNumber}"
            autocomplete="off"
            on:blur="{() => !!$form.phoneNumber?.length && validateField('phoneNumber')}"
            id="phoneNumber"
            placeholder="Phone number"
          />
          <span class="text-red-700">{$errors.phoneNumber}</span>
        </div>
        <div
          class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-gray-200 p-4"
        >
          <button
            id="close-button"
            type="button"
            class="rounded
          bg-purple-600
          px-6
          py-2.5
          text-xs
          font-medium
          uppercase
          leading-tight
          text-white
          shadow-md
          transition duration-150
          ease-in-out hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700
          focus:shadow-lg focus:outline-none
          focus:ring-0
          active:bg-purple-800
          active:shadow-lg"
            data-te-modal-dismiss="modal">Close</button
          >
          <button
            type="submit"
            class="ml-1
      rounded
      bg-blue-600
      px-6
      py-2.5
      text-xs
      font-medium
      uppercase
      leading-tight
      text-white
      shadow-md transition
      duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg
      focus:outline-none
      focus:ring-0
      active:bg-blue-800
      active:shadow-lg"
            disabled="{loading}"
          >
            {#if loading}
              <Loader color="#fff" />
            {:else}
              Create owner account
            {/if}</button
          >
        </div>
      </div>
    </div>
  </div>
</form>
