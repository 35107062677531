<script lang="ts">
import { gql } from '@apollo/client/core';
import Badge from '../../components/Badge.svelte';
import { mutation, query } from 'svelte-apollo';
import { useParams } from 'svelte-navigator';
import CreateTeamModal from './CreateTeamModal.svelte';
import CreateInviteModal from './CreateInviteModal.svelte';
import BusinessStickerModal from './BusinessStickerModal.svelte';
import BusinessTeamSettingsModal from './BusinessTeamSettingsModal.svelte';
import UploadLogoModal from './UploadLogoModal.svelte';
import QrCodeModal from './TipCardModal.svelte';
import RemoveMemberModal from './RemoveMemberModel.svelte';
import TeamTipCardModal from './TeamTipCardModal.svelte';
import { formatDate } from '../../utils/formatDate';
import { displayBalance } from '../../utils/displayBalance';
import { Modal, initTE } from 'tw-elements';
import { onMount } from 'svelte';

onMount(() => {
  initTE({ Modal });
});

const params = useParams();

const generateCode = mutation(gql`
  mutation genCode($businessId: ID!) {
    businesses {
      forAdmin {
        generateBusinessCode(businessId: $businessId) {
          code
        }
      }
    }
  }
`);

const cancelInvite = mutation(gql`
  mutation cancelInvite($inviteId: ID!) {
    invites {
      forAdmin {
        cancelInvite(inviteId: $inviteId) {
          result
        }
      }
    }
  }
`);

const queryResult = query<any>(
  gql`
    query getBusiness($businessId: ID!) {
      businesses {
        forAdmin {
          businesses(
            filter: { businessIds: [$businessId] }
            pagination: { page: 1, recordsPerPage: 10 }
          ) {
            items {
              id
              type
              countryCode
              currencyCode
              name
              code
              address
              ... on BusinessForAdmin {
                ownerId
                logo
                ownerAccount {
                  firstName
                  lastName
                }
                members {
                  code
                  userAccount {
                    id
                    lastName
                    firstName
                  }
                }
                teams {
                  id
                  name
                  code
                  directCode
                  leadUserId
                  leadMember {
                    userAccount {
                      firstName
                      lastName
                    }
                  }
                  balance {
                    amount
                    currency {
                      code
                      decimalPlaces
                    }
                  }
                  pendingBalance {
                    amount
                    currency {
                      code
                      decimalPlaces
                    }
                  }
                  memberIds
                  members {
                    userAccount {
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
      }
      invites {
        forAdmin {
          invites(
            filter: { businessIds: [$businessId], states: [PENDING] }
            pagination: { page: 1, recordsPerPage: 25 }
          ) {
            items {
              id
              label
              phoneNumber
              state
              expiresAt
              createdAt
            }
          }
        }
      }
    }
  `,
  {
    variables: {
      businessId: $params.id,
    },
  },
);

$: graphData = $queryResult.data as any;
$: business = graphData?.businesses?.forAdmin?.businesses?.items[0];
$: invites = graphData?.invites?.forAdmin?.invites?.items;

async function generateCodeFunc() {
  await generateCode({
    variables: {
      businessId: business?.id,
    },
  });
  await queryResult.refetch({
    businessId: $params.id,
  });
}

const removeInvite = (id: string) => async () => {
  await cancelInvite({
    variables: {
      inviteId: id,
    },
  });
  queryResult.refetch();
};
</script>

<main class="mt-2 flex justify-center">
  <div class="w-full overflow-hidden bg-white shadow sm:rounded-lg">
    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg font-medium leading-6 text-gray-900">{business?.name}</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">{business?.address}</p>
      </div>
      <div class="border-t border-gray-200">
        <dl>
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Id</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{business?.id}</dd>
          </div>
          <div class="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Name</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{business?.name}</dd>
          </div>
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Type</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{business?.type}</dd>
          </div>
          <div class="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Country code</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {business?.countryCode}
            </dd>
          </div>
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Currency code</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {business?.currencyCode}
            </dd>
          </div>
          <div class="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Business code</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {#if business?.code}
                {business?.code}
                <BusinessStickerModal
                  businessId="{business?.id ?? ''}"
                  businessCode="{business?.code ?? ''}"
                />
              {:else}
                <button
                  class="text-gray mt-2 rounded-md bg-gray-200 px-4 py-1 text-sm shadow-sm hover:bg-gray-300"
                  on:click="{generateCodeFunc}">Generate business code</button
                >
              {/if}
            </dd>
          </div>
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Address</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {business?.address}
            </dd>
          </div>
          <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Owner</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <a
                class="font-medium text-blue-600 hover:underline dark:text-blue-500"
                href="/users/{business?.ownerId}"
                >{business?.ownerAccount?.firstName} {business?.ownerAccount?.lastName}</a
              >
            </dd>
          </div>
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Members ({business?.members?.length})
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul class="w-96 rounded-lg bg-gray-50 text-gray-900">
                {#each business?.members ?? [] as member}
                  <li class="flex w-full justify-between border-b border-gray-200 px-6 py-2">
                    <span
                      >{member.userAccount?.firstName}
                      {member.userAccount?.lastName}
                      <Badge bg="gray">{member.code}</Badge></span
                    >
                    <QrCodeModal
                      userAccount="{member?.userAccount}"
                      businessId="{business?.id ?? ''}"
                      userCode="{member.code}"
                    /> |
                    <RemoveMemberModal
                      userAccount="{member?.userAccount}"
                      businessId="{business?.id ?? ''}"
                      refresh="{queryResult.refetch}"
                    /> |
                    <a
                      class="font-medium text-blue-600 hover:underline dark:text-blue-500"
                      href="/users/{member.userAccount?.id}">View</a
                    >
                  </li>
                {/each}
                <div class="flex justify-end">
                  <CreateInviteModal
                    refresh="{queryResult.refetch}"
                    businessId="{business?.id}"
                  />
                  <button
                    class="mt-2 rounded-md bg-green-600 px-4 py-1 text-sm text-white shadow-sm hover:bg-green-700"
                    data-te-toggle="modal"
                    data-te-target="#createInviteModal">Invite new member</button
                  >
                </div>
              </ul>
            </dd>
          </div>
          <div class="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Invites</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <div class="flex flex-col">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                    <div class="overflow-hidden">
                      <table class="min-w-full text-center">
                        <thead class="bg-white-50 border-b">
                          <tr>
                            <th scope="col" class="px-6 py-2 text-sm font-medium text-gray-900">
                              Label
                            </th>
                            <th scope="col" class="px-6 py-2 text-sm font-medium text-gray-900">
                              Phone number
                            </th>
                            <th scope="col" class="px-6 py-2 text-sm font-medium text-gray-900">
                              State
                            </th>
                            <th scope="col" class="px-6 py-2 text-sm font-medium text-gray-900">
                              Expires at
                            </th>
                            <th scope="col" class="px-6 py-2 text-sm font-medium text-gray-900">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {#each invites ?? [] as invite}
                            <tr class="bg-white-50 border-b">
                              <td
                                class="whitespace-nowrap px-6 py-2 text-sm font-medium text-gray-900"
                                >{invite.label}</td
                              >
                              <td
                                class="whitespace-nowrap px-6 py-2 text-sm font-light text-gray-900"
                              >
                                {invite.phoneNumber}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-2 text-sm font-light text-gray-900"
                              >
                                {invite.state}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-2 text-sm font-light text-gray-900"
                              >
                                {formatDate(invite.expiresAt)}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-2 text-sm font-light text-gray-900"
                              >
                                <button
                                  on:click="{removeInvite(invite.id)}"
                                  type="button"
                                  class="mb-2 mr-2 rounded-lg bg-red-700 px-2 py-0.5 text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                                >
                                  Cancel
                                </button>
                              </td>
                            </tr>
                          {/each}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </dd>
          </div>
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Teams ({business?.teams?.length})</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <CreateTeamModal refresh="{queryResult.refetch}" businessId="{business?.id}" />
              <ul class="w-96 rounded-lg bg-gray-50 text-gray-900">
                {#each business?.teams ?? [] as team}
                  <li class="border-white-200 w-full space-x-1 border-b px-6 py-2">
                    <span
                      ><b>{team.name}</b>
                      <Badge bg="gray">Code: {team.code}</Badge>
                      <Badge bg="gray">Direct code: {team.directCode}</Badge>
                      <Badge bg="gray">{team.members?.length} Members</Badge>
                      <Badge bg="gray"
                        >Leader {team.leadMember?.userAccount?.firstName ?? 'None'}
                        {team.leadMember?.userAccount?.lastName ?? ''}</Badge
                      ></span
                    >
                    <br />
                    <TeamTipCardModal businessId="{business?.id ?? ''}" team="{team}" /> |<BusinessTeamSettingsModal
                      team="{team}"
                      business="{business}"
                      refresh="{queryResult.refetch}"
                    />
                    <br />
                    <strong>Balance:</strong>
                    {displayBalance(team.balance)} | <strong>Pending balance:</strong>
                    {displayBalance(team.pendingBalance)}
                    <br />
                    {#each team?.members ?? [] as member}
                      <p style="margin-left: 10px">
                        {member.userAccount?.firstName}
                        {member.userAccount?.lastName}
                      </p>
                    {/each}
                  </li>
                {/each}
                <div class="mt-2 flex justify-end">
                  <button
                    class="rounded-md bg-green-600 px-4 py-1 text-sm text-white shadow-sm hover:bg-green-700"
                    data-te-toggle="modal"
                    data-te-target="#createTeamModal">+ Team</button
                  >
                </div>
              </ul>
            </dd>
          </div>
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Logo</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {business?.logo ?? 'No logo'}
              <UploadLogoModal
                businessId="{business?.id ?? ''}"
                refresh="{queryResult.refetch}"
              />
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</main>
