import App from './App.svelte';
import { Modal, initTE } from 'tw-elements';

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
initTE({ Modal });

const app = new App({
  target: document.getElementById('app'),
});

export default app;
