import { format } from 'date-fns';

export function formatDate(date: string | Date, hideSeconds?: boolean): string {
  if (date) {
    const dateFormat = hideSeconds ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy HH:mm:ss';

    return format(new Date(date), dateFormat);
  } else {
    return 'No date';
  }
}
