<script lang="ts">
import type { UserAccount } from 'src/graphql.types';
import { mutation } from 'svelte-apollo';
import { gql } from '@apollo/client/core';

export let userAccount: UserAccount | undefined;
export let businessId: string;
export let refresh;

const removeMemberMutation = mutation(gql`
  mutation removeMember($businessId: ID!, $userId: ID!) {
    businesses {
      forAdmin {
        removeMember(input: { businessId: $businessId, userId: $userId }) {
          result
        }
      }
    }
  }
`);

async function removeMember() {
  await removeMemberMutation({
    variables: {
      businessId,
      userId: userAccount?.id,
    },
  });
  refresh();
}
</script>

<!-- svelte-ignore a11y-missing-attribute -->
<a
  class="font-medium text-blue-600 hover:underline dark:text-blue-500"
  data-te-toggle="modal"
  data-te-target="#removeMemberModal-{userAccount?.id}">Remove</a
>

<div
  data-te-modal-init
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="removeMemberModal-{userAccount?.id}"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div
    data-te-modal-dialog-ref
    class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[700px]"
  >
    <div
      class="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none"
    >
      <div
        class="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4"
      >
        <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">
          You are about to remove <b>{userAccount?.firstName} {userAccount?.lastName}</b> from business
        </h5>
        <button
          type="button"
          class="btn-close box-content h-4 w-4 rounded-none border-none p-1 text-black opacity-50 hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div
        class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-between rounded-b-md border-t border-gray-200 p-4"
      >
        <button
          type="button"
          class="rounded
            bg-red-600
            px-6
            py-2.5
            text-xs
            font-medium
            uppercase
            leading-tight
            text-white
            shadow-md
            transition duration-150
            ease-in-out hover:bg-red-700 hover:shadow-lg focus:bg-red-700
            focus:shadow-lg focus:outline-none
            focus:ring-0
            active:bg-red-800
            active:shadow-lg"
          on:click="{removeMember}"
          data-te-modal-dismiss>Confirm</button
        >
        <button
          type="button"
          class="rounded
              bg-purple-600
              px-6
              py-2.5
              text-xs
              font-medium
              uppercase
              leading-tight
              text-white
              shadow-md
              transition duration-150
              ease-in-out hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700
              focus:shadow-lg focus:outline-none
              focus:ring-0
              active:bg-purple-800
              active:shadow-lg"
          data-te-modal-dismiss>Close</button
        >
      </div>
    </div>
  </div>
</div>
