<script lang="ts">
import { createForm } from 'svelte-forms-lib';
import * as yup from 'yup';
import { authStore, environment } from '../stores';
import { REQUIRED_FIELD } from '../constants/messages';
import Loader from '../components/Loader.svelte';
import { get } from 'svelte/store';

const { authenticateStatus, authenticate } = authStore;

const { form, errors, handleSubmit, validateField } = createForm({
  initialValues: {
    email: '+41111111111',
    password: 'password',
  },
  validationSchema: yup.object().shape({
    email: yup.string().required(REQUIRED_FIELD),
    password: yup.string().min(3).required(REQUIRED_FIELD),
  }),
  onSubmit: async (params) => {
    await authenticate(params);
  },
});

let selectedOption = get(environment.environment);
$: environment.changeEnvironment(selectedOption);
</script>

<div class="mt-5 flex justify-center">
  <div class="space-y-6 sm:w-5/6 xl:w-96">
    <div class="flex justify-center">
      <img src="/logo.png" alt="Coinless logo" width="250px" />
    </div>
    <h2 class="mt-6 text-center text-2xl font-extrabold text-gray-900">Admin Panel</h2>
    <select
      class="form-select m-0
      block
      w-full
      appearance-none
      rounded
      border
      border-solid
      border-gray-300
      bg-white bg-clip-padding bg-no-repeat
      px-3 py-1.5 text-base
      font-normal
      text-gray-700
      transition
      ease-in-out
      focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
      aria-label="Environemnt select"
      bind:value="{selectedOption}"
    >
      <option value="prod" selected="{selectedOption === 'prod'}">Production</option>
      <option value="dev" selected="{selectedOption === 'dev'}">Development</option>
      <option value="local" selected="{selectedOption === 'local'}">Local</option>
    </select>
    <form
      class:error="{$authenticateStatus === 'error'}"
      on:submit="{handleSubmit}"
      class="space-y-6"
    >
      <input
        type="text"
        class="
        form-control
        m-0
        block
        w-full
        rounded
        border
        border-solid
        border-gray-300
        bg-white bg-clip-padding
        px-3 py-1.5 text-base
        font-normal
        text-gray-700
        transition
        ease-in-out
        invalid:border-pink-500 invalid:text-pink-600 focus:border-blue-600 focus:bg-white
        focus:text-gray-700 focus:outline-none
      "
        id="phoneNumber"
        placeholder="Phone number"
        bind:value="{$form.email}"
        on:blur="{() => !!$form.email?.length && validateField('email')}"
      />
      <span class="text-red-700">{$errors.email}</span>
      <input
        type="password"
        class="
        form-control
        m-0
        block
        w-full
        rounded
        border
        border-solid
        border-gray-300
        bg-white bg-clip-padding
        px-3 py-1.5 text-base
        font-normal
        text-gray-700
        transition
        ease-in-out
        invalid:border-pink-500 invalid:text-pink-600 focus:border-blue-600 focus:bg-white
        focus:text-gray-700 focus:outline-none
      "
        bind:value="{$form.password}"
        on:blur="{() => !!$form.password?.length && validateField('password')}"
        id="password"
        placeholder="Password"
      />
      <span class="text-red-700">{$errors.password}</span>
      <button
        class="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        {#if $authenticateStatus === 'fetching'}
          <Loader color="#fff" />
        {:else}
          Login
        {/if}
      </button>
    </form>
  </div>
</div>
